import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // This method renders fallback UI when there is an error.
  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // This method can be used to log error details (optional).
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error("Error caught in Error Boundary:", error, errorInfo);
    // You can log the error to an error reporting service here.
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI when an error occurs.
      return <></>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
