import * as API_LINKS from '../../../config/enermo.links';
import axios from 'axios';

export function fetchDevicesService(auth){
    return  axios({
                method: 'post',
                url: API_LINKS.DEVICE_LIST_URL,
                headers: {
                    Authorization: auth
                },
            });
}