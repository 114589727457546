import React from "react";
import Scopes from "./AETScopes/Scopes";

const AETDashboard = () => {
  return (
    <div className="animated fadeIn">
      <Scopes />
    </div>
  );
};

export default AETDashboard;
