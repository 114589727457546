const daySelections = [
  // {
  //     'label': 'Today',
  //     'value': 0
  // },
  {
    label: "Last 7 days",
    value: 7,
  },
  {
    label: "Last 14 days",
    value: 14,
  },
  {
    label: "Last 30 days",
    value: 30,
  },
  {
    label: "All",
    value: null,
  },
];

const targetTypeSelections = [
  {
    label: "- Please select target type -",
    value: 0,
  },
  {
    label: "Pickup by date",
    value: 1,
  },
  {
    label: "Arrived at destination by date",
    value: 2,
  },
];

const goalsTypeSelections = [
  {
    label: "- Please select goals type -",
    value: 0,
  },
  {
    label: "Dropoff",
    value: 3,
  },
  {
    label: "Pickup",
    value: 4,
  },
];

const monthSelections = [
  {
    label: "This Month",
    value: 0,
  },
  {
    label: "Last Month",
    value: 1,
  },
  {
    label: "Last 2 month(s)",
    value: 2,
  },
  {
    label: "Last 3 month(s)",
    value: 3,
  },
];

const approveDeclineSelections = [
  {
    label: "-- Please Select --",
    value: null,
  },
  {
    label: "Approve",
    value: 1,
  },
  {
    label: "Decline",
    value: 2,
  },
];

const employmentStatusSelections = [
  {
    label: "-- Please Select --",
    value: null,
  },
  {
    label: "Permanent Employee",
    value: 1,
  },
  {
    label: "Freelance Employee",
    value: 2,
  },
];

const checklistLogs = [
  { id: 1, date: "2024-08-01", status: "Completed" },
  { id: 2, date: "2024-08-08", status: "Pending" },
  { id: 3, date: "2024-08-15", status: "Completed" },
];

const pod = [
  { id: 1, date: "2024-08-01", status: "Received" },
  { id: 2, date: "2024-08-08", status: "Delivered" },
  { id: 3, date: "2024-08-15", status: "Received" },
];

const deliveryCostCalculationSelections = [
  {
    label: "-- Please Select --",
    value: null,
  },
  {
    label: "TKM (Tonnage Kilometre) Method",
    value: 1,
  },
  {
    label: "Daily Charge Method",
    value: 2,
  },
];

const covidTestsSelections = [
  {
    label: "-- Please Select --",
    value: null,
  },
  {
    label: "PCR Swab Test",
    value: "PCR Swab Test",
  },
  {
    label: "Rapid Antigen Test",
    value: "Rapid Antigen Test",
  },
];

const covidTestResultsSelections = [
  {
    label: "-- Please Select --",
    value: null,
  },
  {
    label: "(+) Positive",
    value: "Positive",
  },
  {
    label: "(-) Negative",
    value: "Negative",
  },
];

const covidVaccinesSelections = [
  {
    label: "-- Please Select --",
    value: null,
  },
  {
    label: "AstraZeneca",
    value: "AstraZeneca",
  },
  {
    label: "Moderna",
    value: "Moderna",
  },
  {
    label: "Sinopharm",
    value: "Sinopharm",
  },
  {
    label: "Sinovac",
    value: "Sinovac",
  },
];

const covidVaccineSeqSelections = [
  {
    label: "-- Please Select --",
    value: null,
  },
  {
    label: "First Dose",
    value: "First Dose",
  },
  {
    label: "Second Dose",
    value: "Second Dose",
  },
  {
    label: "Third Dose",
    value: "Third Dose",
  },
  {
    label: "Fourth Dose",
    value: "Fourth Dose",
  },
];

const PUBLIC_FACILITY_OWNER = [
  {
    label: "Public Sector",
    value: 1,
  },
  {
    label: "Private Sector",
    value: 2,
  },
];

// const multimodalTransportType = [
//     {
//         'label': 'Ship',
//         'value': 'Ship'
//     },
//     {
//         'label': 'Airplane',
//         'value': 'Airplane'
//     },
// ]

const TAGGING_TYPE = [
  {
    label: "Planned Stop Queing",
    value: 1,
  },
  {
    label: "Drop Point Shifting",
    value: 2,
  },
  {
    label: "Delivery Divertion",
    value: 3,
  },
  {
    label: "New Delivery Task",
    value: 4,
  },
  {
    label: "Break",
    value: 5,
  },
  {
    label: "Gas Filling",
    value: 6,
  },
  {
    label: "Traffic",
    value: 7,
  },
  {
    label: "Accident",
    value: 8,
  },
];

const TAGGING_TYPE_WITHOUT_WO = [
  {
    label: "New Delivery Task",
    value: 4,
  },
  {
    label: "Break",
    value: 5,
  },
  {
    label: "Gas Filling",
    value: 6,
  },
  {
    label: "Traffic",
    value: 7,
  },
  {
    label: "Accident",
    value: 8,
  },
];

const DASHBOARD_COUNT_LINK = [
  {
    link: "wo-review",
    pos: 11,
    groupStatus: 5,
  },
  {
    link: "wo-review",
    pos: 12,
    groupStatus: 3,
  },
  {
    link: "wo-review",
    pos: 13,
    groupStatus: 2,
  },
  {
    link: "wo-review",
    pos: 14,
    groupStatus: 1,
  },
  {
    link: "wo-review",
    pos: 15,
    groupStatus: 4,
  },
  {
    link: "wo-review",
    pos: 22,
    groupStatus: 9,
  },
];

const chartColors = process.env.IS_CARBON_ETHICS
  ? [
      "#C6000F", // Red
      "#FF3510", // Orange tone
      "#FF9506", // Yellow
      "#FE0015", // Bright red
      "#FF3510", // Vivid red-orange
      "#B90024", // Darker red
      "#E80025", // Rich red
      "#FF1D3F", // Pinkish red
      "#D50222", // Bright deep red
      "#a50f15", // Darker deep red
      "#8B0000", // Dark red
      "#9B1B30", // Burgundy
      "#b71c1c", // Darker deep red
      "#880e4f", // Dark magenta
      "#6f0000", // Deep crimson
    ]
  : [
      "#7c9eb6",
      "#71b18c",
      "#396d85",
      "#0a3557",
      "#5c758a",
      "#9daebc",
      "#547289",
      "#82ca9d",
      "#447597",
      "#6c869a",
      "#0f4c75",
      "#d9ad7c",
      "#689ed0",
      "#4f86c6",
    ];

export const shortMonthToNumber = {
  1: "01",
  "01": "01",
  jan: "01",
  Jan: "01",
  2: "02",
  "02": "02",
  feb: "02",
  Feb: "02",
  3: "03",
  "03": "03",
  mar: "03",
  Mar: "03",
  4: "04",
  "04": "04",
  apr: "04",
  Apr: "04",
  5: "05",
  "05": "05",
  may: "05",
  May: "05",
  mei: "05",
  Mei: "05",
  6: "06",
  "06": "06",
  jun: "06",
  Jun: "06",
  7: "07",
  "07": "07",
  jul: "07",
  Jul: "07",
  8: "08",
  "08": "08",
  aug: "08",
  Aug: "08",
  agt: "08",
  Agt: "08",
  agu: "08",
  Agu: "08",
  9: "09",
  "09": "09",
  sep: "09",
  Sep: "09",
  10: "10",
  oct: "10",
  Oct: "10",
  okt: "10",
  Okt: "10",
  11: "11",
  nov: "11",
  Nov: "11",
  nop: "11",
  Nop: "11",
  12: "12",
  des: "12",
  Des: "12",
  dec: "12",
  Dec: "12",
};

export const detectEnv = () =>
  window.location.hostname === "apps.automa.id"
    ? "production"
    : window.location.hostname === "devapps.automa.id"
    ? "development"
    : window.location.hostname === "telkomselesgdashboard.carbonethics.co"
    ? "carbonEthicsProduction"
    : "local";

const environment = {
  production: {
    WALLET_PAGE: true,
    WALLET_BALANCE: true,
    WALLET_PAYMENT_INTERCOMPANY: true,
    INTERCOMPANY_INVOICE_PDF: true,
    WALLET_PAYMENT_3PL: true,
    LIVE_MONITORING_V2: true,
    LIVE_MONITORING_V2_CCTV: true,
    LIVE_MONITORING_V2_SOS: false,
    TIRE_MANAGEMENT: false,
    STORING: false,
    MENUBAR_CARBONX_V2: false,
    CARBONX_V2: false,
    STORING_V2: false,
    GET_ALL_ADDRESS_ON_FLEET_OBD_HISTORY: [
      40, // damas cipta mandiri
    ],
    GET_ALL_ADDRESS_ON_LIVE_MONITORING: [
      40, // damas cipta mandiri
    ],
    PICTURE_ON_DELIVERY_ORDER_ON_STOPS: true,
    VIDEO_OBD_CCTV_ON_DELIVERY_ORDER_ON_STOPS: true,
    MY_PERTAMINA_COMPARE_REPORT_FUEL: true,
    DO_AUDIT_TEMPERATURE: false,
    DEMO_MODE: false,
    REDUX_LOGGER: false,
    HR_DASHBOARD: true,
    SUMMARY_WIDGET_DRIVER_NEW: false,
    TOTAL_USERS_LIMIT_SUBS: 30,
    CHECKSHEET_WEEKLY: true,
    CHECKSHEET_WEEKLY_SUBMISSION: false,
    CCTV_EZVIZ_PLAYBACK: true,
    DEMO_SCOPE_1: false,
    DEMO_SCOPE_2: false,
    DEMO_SCOPE_3: false,
    DEMO_COMPANY_EMISSION_SCORE_CARD_SCOPE_3: false,
  },
  carbonEthicsProduction: {
    WALLET_PAGE: false,
    WALLET_BALANCE: false,
    WALLET_PAYMENT_INTERCOMPANY: false,
    INTERCOMPANY_INVOICE_PDF: false,
    WALLET_PAYMENT_3PL: false,
    LIVE_MONITORING_V2: false,
    LIVE_MONITORING_V2_CCTV: false,
    LIVE_MONITORING_V2_SOS: false,
    TIRE_MANAGEMENT: false,
    STORING: false,
    MENUBAR_CARBONX_V2: true,
    CARBONX_V2: true,
    STORING_V2: false,
    GET_ALL_ADDRESS_ON_FLEET_OBD_HISTORY: false,
    GET_ALL_ADDRESS_ON_LIVE_MONITORING: false,
    PICTURE_ON_DELIVERY_ORDER_ON_STOPS: false,
    VIDEO_OBD_CCTV_ON_DELIVERY_ORDER_ON_STOPS: false,
    MY_PERTAMINA_COMPARE_REPORT_FUEL: false,
    DO_AUDIT_TEMPERATURE: false,
    DEMO_MODE: false,
    REDUX_LOGGER: false,
    HR_DASHBOARD: false,
    SUMMARY_WIDGET_DRIVER_NEW: false,
    TOTAL_USERS_LIMIT_SUBS: 30,
    CHECKSHEET_WEEKLY: false,
    CHECKSHEET_WEEKLY_SUBMISSION: false,
    CCTV_EZVIZ_PLAYBACK: false,
    DEMO_SCOPE_1: true,
    DEMO_SCOPE_2: true,
    DEMO_SCOPE_3: true,
    DEMO_COMPANY_EMISSION: true,
    DEMO_COMPANY_EMISSION_SCORE_CARD_SCOPE_3: true,
  },
  development: {
    WALLET_PAGE: true,
    WALLET_BALANCE: true,
    WALLET_PAYMENT_INTERCOMPANY: true,
    INTERCOMPANY_INVOICE_PDF: true,
    WALLET_PAYMENT_3PL: true,
    LIVE_MONITORING_V2: true,
    LIVE_MONITORING_V2_CCTV: true,
    LIVE_MONITORING_V2_SOS: false,
    TIRE_MANAGEMENT: false,
    STORING: true,
    MENUBAR_CARBONX_V2: true,
    CARBONX_V2: true,
    STORING_V2: true,
    GET_ALL_ADDRESS_ON_FLEET_OBD_HISTORY: true,
    GET_ALL_ADDRESS_ON_LIVE_MONITORING: true,
    PICTURE_ON_DELIVERY_ORDER_ON_STOPS: true,
    VIDEO_OBD_CCTV_ON_DELIVERY_ORDER_ON_STOPS: true,
    MY_PERTAMINA_COMPARE_REPORT_FUEL: true,
    DO_AUDIT_TEMPERATURE: true,
    DEMO_MODE: false,
    REDUX_LOGGER: false,
    HR_DASHBOARD: true,
    SUMMARY_WIDGET_DRIVER_NEW: false,
    TOTAL_USERS_LIMIT_SUBS: 30,
    CHECKSHEET_WEEKLY: true,
    CHECKSHEET_WEEKLY_SUBMISSION: true,
    CCTV_EZVIZ_PLAYBACK: true,
    DEMO_SCOPE_1: true,
    DEMO_SCOPE_2: true,
    DEMO_SCOPE_3: true,
    DEMO_COMPANY_EMISSION: true,
    DEMO_COMPANY_EMISSION_SCORE_CARD_SCOPE_3: true,
  },
  local: {
    WALLET_PAGE: true,
    WALLET_BALANCE: true,
    WALLET_PAYMENT_INTERCOMPANY: true,
    INTERCOMPANY_INVOICE_PDF: true,
    WALLET_PAYMENT_3PL: true,
    LIVE_MONITORING_V2: true,
    LIVE_MONITORING_V2_CCTV: true,
    LIVE_MONITORING_V2_SOS: false,
    TIRE_MANAGEMENT: false,
    STORING: true,
    MENUBAR_CARBONX_V2: true,
    CARBONX_V2: true,
    STORING_V2: true,
    GET_ALL_ADDRESS_ON_FLEET_OBD_HISTORY: true,
    GET_ALL_ADDRESS_ON_LIVE_MONITORING: true,
    PICTURE_ON_DELIVERY_ORDER_ON_STOPS: true,
    VIDEO_OBD_CCTV_ON_DELIVERY_ORDER_ON_STOPS: true,
    MY_PERTAMINA_COMPARE_REPORT_FUEL: true,
    DO_AUDIT_TEMPERATURE: true,
    DEMO_MODE: false,
    REDUX_LOGGER: false,
    HR_DASHBOARD: true,
    SUMMARY_WIDGET_DRIVER_NEW: false,
    TOTAL_USERS_LIMIT_SUBS: 100000,
    CHECKSHEET_WEEKLY: true,
    CHECKSHEET_WEEKLY_SUBMISSION: true,
    CCTV_EZVIZ_PLAYBACK: true,
    DEMO_SCOPE_1: true,
    DEMO_SCOPE_2: true,
    DEMO_SCOPE_3: true,
    DEMO_COMPANY_EMISSION: true,
    DEMO_COMPANY_EMISSION_SCORE_CARD_SCOPE_3: true,
  },
};

const activeFeature = environment[detectEnv()];

export {
  daySelections,
  targetTypeSelections,
  goalsTypeSelections,
  monthSelections,
  approveDeclineSelections,
  employmentStatusSelections,
  deliveryCostCalculationSelections,
  covidTestsSelections,
  covidTestResultsSelections,
  covidVaccinesSelections,
  covidVaccineSeqSelections,
  PUBLIC_FACILITY_OWNER,
  TAGGING_TYPE,
  TAGGING_TYPE_WITHOUT_WO,
  DASHBOARD_COUNT_LINK,
  activeFeature,
  chartColors,
  checklistLogs,
  pod,
};
