import * as API_LINKS from '../../../config/enermo.links';
import axios from 'axios';

export function fetchSensorListService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.SENSOR_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}

export function fetchSensorTypeListService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.SENSOR_TYPE_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}

export function editSensorService(sensor, auth){
    return axios({
        method: 'post',
        url: API_LINKS.SENSOR_EDIT_URL,
        headers: {
            Authorization: auth,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: sensor
    })
}

export function deleteSensorService(sensorId, auth){
    return axios({
        method: 'post',
        url: API_LINKS.SENSOR_DELETE_URL,
        headers: {
            Authorization: auth
        },
        data : {id: sensorId}
    })
}

//Fabricator

export function fetchSensorFabricationListService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.SENSOR_FABRICATOR_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}

export function createFabricatorService(newFabricator, auth){
    return axios({
        method: 'post',
        url: API_LINKS.SENSOR_FABRICATOR_ADD_URL,
        headers: {
            Authorization: auth
        },
        data: newFabricator 
    })
}

export function editFabricatorService(fabricator, auth){
    return axios({
        method: 'post',
        url: API_LINKS.SENSOR_FABRICATOR_EDIT_URL,
        headers: {
            Authorization: auth
        },
        data: fabricator
    })
}

export function deleteFabricatorService(fabricatorId, auth){
    return axios({
        method: 'post',
        url: API_LINKS.SENSOR_FABRICATOR_DELETE_URL,
        headers: {
            Authorization: auth
        },
        data : {id: fabricatorId}
    })
}

//Class

export function fetchSensorClassListService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.SENSOR_CLASS_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}

export function createSensorClassService(newSensorClass, auth){
    return axios({
        method: 'post',
        url: API_LINKS.SENSOR_CLASS_ADD_URL,
        headers: {
            Authorization: auth
        },
        data: newSensorClass 
    })
}

export function editSensorClassService(sensorClass, auth){
    return axios({
        method: 'post',
        url: API_LINKS.SENSOR_CLASS_EDIT_URL,
        headers: {
            Authorization: auth
        },
        data: sensorClass
    })
}

export function deleteSensorClassService(sensorClassId, auth){
    return axios({
        method: 'post',
        url: API_LINKS.SENSOR_CLASS_DELETE_URL,
        headers: {
            Authorization: auth
        },
        data : {id: sensorClassId}
    })
}
