import {
    FETCH_GENERAL_SETTING_LIST,
    RECEIVE_GENERAL_SETTING_LIST,
    FAILED_GENERAL_SETTING_LIST,

    FETCH_BUDGET_SETTING_LIST,
    RECEIVE_BUDGET_SETTING_LIST,
    FAILED_BUDGET_SETTING_LIST,
} from "../../types/enermo/setting.types";

import {fetchGeneralSettingService,
            fetchBudgetSettingService}
from  "./services/setting.service";

//General Settings
export const fetchGeneralSetting = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_GENERAL_SETTING_LIST,
        });
        const response = await fetchGeneralSettingService(auth);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_GENERAL_SETTING_LIST,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_GENERAL_SETTING_LIST,
        });
       return Promise.reject(error);
    }
};

//Budget Settings
export const fetchBudgetSetting = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_BUDGET_SETTING_LIST,
        });
        const response = await fetchBudgetSettingService(auth);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_BUDGET_SETTING_LIST,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_BUDGET_SETTING_LIST,
        });
       return Promise.reject(error);
    }
};