import React, { createContext, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { requestForToken, onMessageListener } from "../../helpers/firebase";
import { Button, FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap";

function ToastDisplay({ title, body }) {
  return (
    <div>
      <p>
        <b>{title}</b>
      </p>
      <p>{body}</p>
    </div>
  );
}

export const NotificationContext = createContext();

const NotificationWrapper = ({ children }) => {
  const [reloadMessages, setReloadMessages] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeniedNotif, setIsOpenDeniedNotif] = useState(false);
  const [oneTime, setOneTime] = useState(true);
  const [notification, setNotification] = useState(null);


  onMessageListener()
    .then((payload) => {
      setNotification(payload.notification);
    })
    .catch((err) => console.log("failed: ", err));

  const onPermission = useCallback(() => {
    requestForToken({
      onRequest: () => {
        setIsOpen(true)
      },
      onError: () => null
    });
  }, [])

  useEffect(() => {
    if (oneTime) {
      setOneTime(false);
      if (Notification.permission === "default") {
        setIsOpen(true)
      }
      if (Notification.permission === "denied") {
        setIsOpenDeniedNotif(true)
      }
    }
  }, [oneTime, Notification])

  useEffect(() => {
    if (notification !== null) {
      toast.success(
        <ToastDisplay title={notification.title} body={notification.body} />,
        { position: "bottom-right" }
      );
      setNotification(null);
      setReloadMessages(true);
    }
  }, [notification]);


  return <NotificationContext.Provider value={{ reloadMessages, setReloadMessages }}>
    {children}
    {process.env.IS_PLATFORM?<>
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <ModalHeader
        toggle={() => setIsOpen(false)}>
        Notification Permission
      </ModalHeader>
      <ModalBody>
        <form onSubmit={(e) => {
          e.preventDefault();
          onPermission();
          setIsOpen(false)
        }}>
          <p>Allow notification request?</p>
          <FormGroup>
            <Button
              type="Submit"
              color="primary"
              className="mr-1"
            >
              Yes
            </Button>
            <Button
              type="button"
              color="warning"
              className="mr-1"
              onClick={() => setIsOpen(false)}
            >
              No
            </Button>
          </FormGroup>
        </form>
      </ModalBody>
    </Modal>
    <Modal
      size="md"
      isOpen={isOpenDeniedNotif}
      toggle={() => setIsOpen(false)}
    >
      <ModalHeader
        toggle={() => setIsOpenDeniedNotif(false)}>
        Notification Permission
      </ModalHeader>
      <ModalBody>
        <form onSubmit={(e) => {
          e.preventDefault();
          setIsOpenDeniedNotif(false)
        }}>
          <p>Notifications blocked. Please enable them in your browser.</p>
          <FormGroup>
            <Button
              type="Submit"
              color="primary"
              className="mr-1"
            >
              Okay
            </Button>
          </FormGroup>
        </form>
      </ModalBody>
    </Modal>
    </>:null}
  </NotificationContext.Provider>;
};

export default NotificationWrapper;
