import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardBody, CardFooter } from "reactstrap";
import classNames from "classnames";
import { mapToCssModules } from "reactstrap/lib/utils";

const propTypes = {
  header: PropTypes.any,
  mainText: PropTypes.string,
  icon: PropTypes.node,
  other: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  footer: PropTypes.bool,
  link: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  stat: PropTypes.number,
  months: PropTypes.number,
  mName: PropTypes.any,
};

const defaultProps = {
  header: "0",
  mainText: "Active Users",
  icon: null,
  color: "success", // Default to a Bootstrap color
  other: "",
  variant: "0",
  link: "#",
  stat: 0,
  months: 0,
  mName: "",
};

const isBootstrapColor = (color) => {
  const bootstrapColors = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];
  return bootstrapColors.includes(color);
};

const SummaryWidgetsNew = ({
  className,
  cssModule,
  header,
  mainText,
  icon,
  color,
  footer,
  link,
  other,
  months,
  variant,
  children,
  mName,
  ...attributes
}) => {
  const padding =
    variant === "0"
      ? { card: "p-3", icon: "p-3", lead: "mt-2" }
      : variant === "1"
      ? {
          card: "p-0",
          icon: "p-4",
          lead: "pt-3",
        }
      : { card: "p-0", icon: "p-4 px-5", lead: "pt-3" };

  const cardClasses = mapToCssModules(
    classNames(className, "clearfix", padding.card),
    cssModule
  );

  // Set header color to match the background color
  const headerStyle = !isBootstrapColor(color) ? { color: color } : {};

  const leadClasses = classNames(
    "h5 mb-0",
    padding.lead,
    isBootstrapColor(color) ? `text-${color}` : ""
  );
  
  const blockIcon = (icon) => {
    if (!icon) return null;

    const iconStyle = !isBootstrapColor(color)
      ? { backgroundColor: color, color: "#fff" }
      : {};

    const iconClasses = classNames(
      isBootstrapColor(color) ? `bg-${color} text-white` : "text-white",
      padding.icon,
      "font-1xl mr-3 float-left d-flex align-items-center justify-content-center"
    );

    return (
      <div className={iconClasses} style={iconStyle}>
        {icon}
      </div>
    );
  };

  const cardFooter = () => {
    if (footer) {
      return (
        <CardFooter className="px-3 py-2">
          <Link
            to={{
              pathname: link,
              state: {
                cardName: mainText,
                details: other,
                mName: mName,
                months: months,
              },
            }}
          >
            <span className="font-weight-bold font-xs btn-block text-muted">
              View Details
              <i className="fa fa-angle-right float-right font-lg"></i>
            </span>
          </Link>
        </CardFooter>
      );
    }
  };

  return (
    <Card>
      <CardBody className={cardClasses} {...attributes}>
        {blockIcon(icon)}
        <div className={leadClasses} style={headerStyle}>
          {header}
        </div>
        <div className="text-muted text-uppercase font-xs">{other}</div>
        <div className="text-muted text-uppercase font-weight-bold font-xs">
          {mainText}
        </div>
        <div className="mt-1">{children}</div>
      </CardBody>
      {cardFooter()}
    </Card>
  );
};

SummaryWidgetsNew.propTypes = propTypes;
SummaryWidgetsNew.defaultProps = defaultProps;

export default SummaryWidgetsNew;
