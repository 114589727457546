import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
// import SummaryWidgets from "../../../Widgets/SummaryWidgets";
import { BiSolidReport } from "react-icons/bi";
import { FaFileSignature, FaLeaf } from "react-icons/fa";
import { MdCloudDone } from "react-icons/md";
import SummaryWidgetsNew from "src/views/Widgets/SummaryWidgetsNew";
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary";

class Scopes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scopesEmission: {
        scope1: 0,
        scope2: 0,
        scope3: 0,
      },
      scopesWo: {
        scope1: 0,
        scope3: 0,
      },
      totalOutboundItems: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.scopesEmission) !==
      JSON.stringify(this.props.scopesEmission)
    ) {
      this.setState({
        scopesEmission: this.props.scopesEmission,
      });
    }
    if (prevProps.scopesWo !== this.props.scopesWo) {
      this.setState({
        scopesWo: this.props.scopesWo,
      });
    }
    if (prevProps.totalOutboundItems !== this.props.totalOutboundItems) {
      this.setState({
        totalOutboundItems: this.props.totalOutboundItems,
      });
    }
  }

  sum = (obj) => {
    let result = 0;
    let value = 0;
    for (var key of Object.keys(obj)) {
      value = obj[key] ? Number(obj[key]) : 0;
      result += value;
    }
    return result;
  };

  getTotalCarbonEmission = (type) => {
    const { scopesEmission } = this.state;
    if (type === 1)
      return (
        (this.sum(scopesEmission)
          ? this.numberStringFormatter(this.sum(scopesEmission).toFixed(2))
          : 0) + " kgCO2e"
      );
    if (type === 2)
      return (
        (this.sum(scopesEmission)
          ? this.numberStringFormatter(
              (this.sum(scopesEmission) / 1000).toFixed(2)
            )
          : 0) + " tCO2e"
      );
    if (type === 3)
      return this.sum(scopesEmission) ? this.sum(scopesEmission).toFixed(2) : 0;
  };

  getTotalScopeWo = (type) => {
    const { scopesWo } = this.state;
    if (type === 1)
      return (
        (this.sum(scopesWo)
          ? this.numberStringFormatter(this.sum(scopesWo))
          : 0) + " delivery"
      );
    if (type === 2) return this.sum(scopesWo) ? this.sum(scopesWo) : 0;
  };

  getCarbonIntensity = () => {
    const { scopesWo } = this.state;
    if (this.getTotalScopeWo(2) === 0 || this.state.totalOutboundItems === 0)
      return "0 kgCO2e/delivery or 0 kgCO2e/pce";
    return (
      this.numberStringFormatter(
        this.getTotalCarbonEmission(3) /
          Object.keys(scopesWo).length /
          this.getTotalScopeWo(2).toFixed(2)
      ) +
      " kgCO2e/delivery or " +
      this.numberStringFormatter(
        this.getTotalCarbonEmission(3) /
          Object.keys(scopesWo).length /
          this.state.totalOutboundItems.toFixed(2)
      ) +
      " kgCO2e/pce"
    );
  };

  numberStringFormatter = (_digit) => {
    if (!_digit) return 0;
    return parseFloat(_digit)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  render() {
    let reportingTitle = `Reporting Company: ${localStorage.getItem(
      "AutomaCmp"
    )}, Period ${moment().startOf("year").format("DD MMMM YYYY")} - (Today)`;
    return (
      <>
        <h4>{reportingTitle}</h4>
        <hr></hr>
        <Row>
          <Col md={3} xs={12}>
            <ErrorBoundary>
              <SummaryWidgetsNew
                header={this.getTotalCarbonEmission(2)}
                mainText={"Total Carbon Emission"}
                icon={<MdCloudDone size={25} />}
                color={"#0085FF"}
              />
            </ErrorBoundary>
          </Col>
          <Col md={3} xs={12}>
            <ErrorBoundary>
              <SummaryWidgetsNew
                header={this.getTotalScopeWo(1)}
                mainText={"Total Work Order"}
                icon={<BiSolidReport size={25} />}
                color={"#E43D3D"}
              />
            </ErrorBoundary>
          </Col>
          <Col md={3} xs={12}>
            <ErrorBoundary>
              <SummaryWidgetsNew
                header={
                  this.numberStringFormatter(this.state.totalOutboundItems) +
                  " pce/s"
                }
                mainText={"Total Outbound Items"}
                icon={<FaFileSignature size={25} />}
                color={"#F98600"}
              />
            </ErrorBoundary>
          </Col>
          <Col md={3} xs={12}>
            <ErrorBoundary>
              <SummaryWidgetsNew
                header={this.getCarbonIntensity()}
                mainText={"Carbon Intensity"}
                icon={<FaLeaf size={25} />}
                color={"#00952A"}
              />
            </ErrorBoundary>
          </Col>
        </Row>
      </>
    );
  }
}
export default Scopes;
