import {
    FETCH_VISUAL_MODEL_LIST,
    RECEIVE_VISUAL_MODEL_LIST,
    FAILED_VISUAL_MODEL_LIST,
    
    FETCH_VISUAL_MODEL_DEVICE_LIST,
    RECEIVE_VISUAL_MODEL_DEVICE_LIST,
    FAILED_VISUAL_MODEL_DEVICE_LIST,

    FETCH_VISUAL_MODEL_SENSOR_LIST,
    RECEIVE_VISUAL_MODEL_SENSOR_LIST,
    FAILED_VISUAL_MODEL_SENSOR_LIST,
    
    FETCH_VISUAL_MODEL_UTILITIES_LIST,
    RECEIVE_VISUAL_MODEL_UTILITIES_LIST,
    FAILED_VISUAL_MODEL_UTILITIES_LIST,

} from "../../types/enermo/visual-modeling.types";

import {fetchVisualModelService, 
        fetchVisualModelDeviceService, 
        fetchVisualModelSensorService,
        fetchVisualModelUtilitiesService,}
from  "./services/visual-modeling.service";

//Visual Model
export const fetchVisualModel = () => async (dispatch, getState) => {
    try {
        let auth = getState().authStore.authData.result.token;
        dispatch({
            type: FETCH_VISUAL_MODEL_LIST,
        });
        const response = await fetchVisualModelService(auth);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_VISUAL_MODEL_LIST,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_VISUAL_MODEL_LIST,
        });
       return Promise.reject(error);
    }
};

//Visual Model Device
export const fetchVisualModelDevice = () => async (dispatch) => {
  try {
      var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      var auth = loginCreds.token;
      dispatch({
          type: FETCH_VISUAL_MODEL_DEVICE_LIST,
      });
      const response = await fetchVisualModelDeviceService(auth);
      if(response.status === 200){

              dispatch({
                  type: RECEIVE_VISUAL_MODEL_DEVICE_LIST,
                  payload: response.data
              });

      }
  } catch(error) {
      dispatch({
          type: FAILED_VISUAL_MODEL_DEVICE_LIST,
      });
     return Promise.reject(error);
  }
};


//Visual Model Sensor
export const fetchVisualModelSensor = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_VISUAL_MODEL_SENSOR_LIST,
        });
        const response = await fetchVisualModelSensorService(auth);
        if(response.status === 200){

                dispatch({
                    type: RECEIVE_VISUAL_MODEL_SENSOR_LIST,
                    payload: response.data
                });

        }
    } catch(error) {
        dispatch({
            type: FAILED_VISUAL_MODEL_SENSOR_LIST,
        });
       return Promise.reject(error);
    }
};


//Visual ModelUtilities Utilities
export const fetchVisualModelUtilities = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_VISUAL_MODEL_UTILITIES_LIST,
        });
        const response = await fetchVisualModelUtilitiesService(auth);
        if(response.status === 200){

                dispatch({
                    type: RECEIVE_VISUAL_MODEL_UTILITIES_LIST,
                    payload: response.data
                });

        }
    } catch(error) {
        dispatch({
            type: FAILED_VISUAL_MODEL_UTILITIES_LIST,
        });
       return Promise.reject(error);
    }
};
