const BASE_URL = "https://api.automa.id/api";

const USER_URL = BASE_URL + "/user";
const LOGIN_URL = USER_URL + "/login";
const REGISTER_URL = USER_URL + "/register";
const USER_LIST_URL = USER_URL + "/list"
const USER_STATUS_URL = USER_URL + "/status"
const CHANGE_PASSWORD_URL = BASE_URL + '/user/changepassword'

//Location
const LOCATION_BASE = BASE_URL + '/landlord/location'
const LOCATION_TYPE_LIST_URL = LOCATION_BASE + '/type/list'
const LOCATION_TYPE_ADD_URL = LOCATION_BASE + '/type/add'
const LOCATION_TYPE_EDIT_URL = LOCATION_BASE + '/type/edit'
const LOCATION_TYPE_DELETE_URL = LOCATION_BASE + '/type/delete'
const LOCATION_LIST_URL = LOCATION_BASE + '/list'
const LOCATION_ADD_URL = LOCATION_BASE + '/add'
const LOCATION_EDIT_URL = LOCATION_BASE + '/edit'
const LOCATION_DELETE_URL = LOCATION_BASE + '/delete'

//Sensors
const SENSOR_BASE = BASE_URL + '/landlord/sensor'
const SENSOR_FABRICATOR_LIST_URL = SENSOR_BASE + '/fab/list'
const SENSOR_FABRICATOR_ADD_URL = SENSOR_BASE + '/fab/add'
const SENSOR_FABRICATOR_EDIT_URL = SENSOR_BASE + '/fab/edit'
const SENSOR_FABRICATOR_DELETE_URL = SENSOR_BASE + '/fab/delete'
const SENSOR_CLASS_LIST_URL = SENSOR_BASE + '/class/list'
const SENSOR_CLASS_ADD_URL = SENSOR_BASE + '/class/add'
const SENSOR_CLASS_EDIT_URL = SENSOR_BASE + '/class/edit'
const SENSOR_CLASS_DELETE_URL = SENSOR_BASE + '/class/delete'
const SENSOR_TYPE_LIST_URL = SENSOR_BASE + '/type/list'
const SENSOR_TYPE_ADD_URL = SENSOR_BASE + '/type/add'
const SENSOR_LIST_URL = SENSOR_BASE + '/list'
const SENSOR_ADD_URL = SENSOR_BASE + '/add'
const SENSOR_EDIT_URL = SENSOR_BASE + '/edit'
const SENSOR_DELETE_URL = SENSOR_BASE + '/delete'

//Device
const DEVICE_BASE = BASE_URL + '/landlord/sensor/device'
const DEVICE_LIST_URL = DEVICE_BASE + '/find'
const DEVICE_ADD_URL = DEVICE_BASE + '/put'
const DEVICE_EDIT_URL = DEVICE_BASE + '/edit'
const DEVICE_DELETE_URL = DEVICE_BASE + '/delete'
const DEVICE_INSTALL_BASE = SENSOR_BASE + '/install'
const DEVICE_INSTALLATION_LIST_URL = DEVICE_INSTALL_BASE + '/list'
const DEVICE_INSTALLATION_ADD_URL = DEVICE_INSTALL_BASE + '/add'
const DEVICE_INSTALLATION_REMOVAL_URL = DEVICE_INSTALL_BASE + '/remove'
const DEVICE_INSTALLATION_CALIBRATION_URL = DEVICE_INSTALL_BASE + '/update'
const DEVICE_FABRICATOR_LIST_URL = DEVICE_BASE + '/fabricator/find'

//Utility
const LL_UTILITY_BASE = LOCATION_BASE + '/utility'
const UTILITY_LIST_URL = LL_UTILITY_BASE + '/list'
const UTILITY_ADD_URL = LL_UTILITY_BASE + '/add'
const UTILITY_EDIT_URL = LL_UTILITY_BASE + '/edit'
const UTILITY_DELETE_URL = LL_UTILITY_BASE + '/delete'
const UTILITY_TYPE_LIST_URL = LL_UTILITY_BASE + '/type/list'
const UTILITY_TYPE_ADD_URL = LL_UTILITY_BASE + '/type/add'
const UTILITY_TYPE_EDIT_URL = LL_UTILITY_BASE + '/type/edit'
const UTILITY_TYPE_DELETE_URL = LL_UTILITY_BASE + '/type/delete'
const RESOURCE_USAGE_TYPE_LIST_URL = LL_UTILITY_BASE + '/resource/list'
const RESOURCE_USAGE_TYPE_ADD_URL = LL_UTILITY_BASE + '/resource/add'
const USAGE_SCHEDULE_LIST_URL = LL_UTILITY_BASE + '/usage/list'
const USAGE_SCHEDULE_ADD_URL = LL_UTILITY_BASE + '/usage/add'

//Visual Modeling
const VISUAL_MODEL_LIST = LOCATION_BASE + '/visual/model/list'
const VISUAL_MODEL_DEVICE_LIST = LOCATION_BASE + '/visual/model/device/list'
const VISUAL_MODEL_SENSOR_LIST = LOCATION_BASE + '/visual/model/sensor/list'
const VISUAL_MODEL_UTILITIES_LIST = LOCATION_BASE + '/visual/model/utilities/list'

//Live Monitoring
const LIVE_MONITORING_FETCH_URL = SENSOR_BASE + '/device/monitoring'

//Processing Monitoring Data
const KWH_AVERAGE_URL = LIVE_MONITORING_FETCH_URL + '/kwhaverage/v2'
const SENSOR_HISTORY_GROUP_URL = LIVE_MONITORING_FETCH_URL + '/sensor/group/datetime/v2'
const SENSOR_ALERT_GROUP_URL = LIVE_MONITORING_FETCH_URL + '/sensor/group/max-cap/v2'
const DEVICE_ACTIVE_TIME_URL = LIVE_MONITORING_FETCH_URL + '/device-active-time/v2'

//Settings
const SETTINGS_BASE = BASE_URL + '/landlord/setting'
const GENERAL_SETTING_LIST_URL = SETTINGS_BASE + '/list'
const BUDGET_SETTING_LIST_URL = SETTINGS_BASE + '/budget/list'

//Companies
const COMPANY_LIST_URL = USER_URL + '/company/list'
const COMPANY_EDIT_URL = USER_URL + '/company/edit'

export {

    LOGIN_URL,
    REGISTER_URL,
    CHANGE_PASSWORD_URL,
    USER_LIST_URL,
    USER_STATUS_URL,

    //Location related
    LOCATION_TYPE_LIST_URL,
    LOCATION_TYPE_ADD_URL,
    LOCATION_TYPE_EDIT_URL,
    LOCATION_TYPE_DELETE_URL,
    LOCATION_LIST_URL,
    LOCATION_ADD_URL,
    LOCATION_EDIT_URL,
    LOCATION_DELETE_URL,
    
    //Device
    DEVICE_LIST_URL, 
    DEVICE_ADD_URL, 
    DEVICE_EDIT_URL, 
    DEVICE_DELETE_URL,
    DEVICE_FABRICATOR_LIST_URL,

    //Sensor
    SENSOR_FABRICATOR_LIST_URL,
    SENSOR_FABRICATOR_ADD_URL,
    SENSOR_FABRICATOR_EDIT_URL,
    SENSOR_FABRICATOR_DELETE_URL,
    SENSOR_CLASS_LIST_URL,
    SENSOR_CLASS_ADD_URL,
    SENSOR_CLASS_EDIT_URL,
    SENSOR_CLASS_DELETE_URL,
    SENSOR_TYPE_LIST_URL,
    SENSOR_TYPE_ADD_URL,
    SENSOR_LIST_URL,
    SENSOR_ADD_URL,
    SENSOR_EDIT_URL,
    SENSOR_DELETE_URL,   

    //Utility
    UTILITY_LIST_URL,
    UTILITY_ADD_URL,
    UTILITY_EDIT_URL,
    UTILITY_DELETE_URL,
    UTILITY_TYPE_LIST_URL,
    UTILITY_TYPE_ADD_URL,
    UTILITY_TYPE_EDIT_URL,
    UTILITY_TYPE_DELETE_URL,

    RESOURCE_USAGE_TYPE_LIST_URL,
    RESOURCE_USAGE_TYPE_ADD_URL,
    USAGE_SCHEDULE_LIST_URL,
    USAGE_SCHEDULE_ADD_URL,

    //Installation
    DEVICE_INSTALLATION_LIST_URL,
    DEVICE_INSTALLATION_ADD_URL,
    DEVICE_INSTALLATION_REMOVAL_URL,
    DEVICE_INSTALLATION_CALIBRATION_URL,

    //Visual Model
    VISUAL_MODEL_LIST,
    VISUAL_MODEL_DEVICE_LIST,
    VISUAL_MODEL_SENSOR_LIST,
    VISUAL_MODEL_UTILITIES_LIST,

    //Settings
    GENERAL_SETTING_LIST_URL,
    BUDGET_SETTING_LIST_URL,

    //Live Monitoring
    LIVE_MONITORING_FETCH_URL,

    //Processing Monitoring Data
    KWH_AVERAGE_URL,
    SENSOR_HISTORY_GROUP_URL,
    SENSOR_ALERT_GROUP_URL,
    DEVICE_ACTIVE_TIME_URL,

    //Companies
    COMPANY_LIST_URL,
    COMPANY_EDIT_URL
}