import moment from 'moment'

export function handleLoginErrorResponseCode(error) {
    try{
        const STATUS_CODES_ERROR = {
            400 : error.response.data.message ? error.response.data.message : "Invalid username or password! Please try again!",
            401 : "Invalid username or password! Please try again!",
            404 : "Server cannot be contacted! Please ask your system administrator!",
            500 : "Server cannot be contacted! Please ask your system administrator!"       
        }
        
        if (error.response) {
            return STATUS_CODES_ERROR[error.response.status];
        } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            return "Request error! Please try again later...";
        } else {
            // Something happened in setting up the request and triggered an Error
            return  "Something went wrong... Please try again later..."
        }
    }catch(e){
        return "Request error! Please try again later...";
    }
}

export function handleErrorResponseCode(error) {
        try{
        const STATUS_CODES_ERROR = {
            400 : error.response.data.message ? error.response.data.message : "Invalid request!",
            401 : "Your account is unauthorized to perform the action!",
            403 : "Unauthorized Access! Please try to logout..",
            404 : "URL Not Found!",
            500 : "Server cannot be contacted! Please ask your system administrator!"
        }
        if (error.response) {
            console.log(error.message)
            return STATUS_CODES_ERROR[error.response.status];
        } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            return "Request error! Please try again later...";
        } else {
            // Something happened in setting up the request and triggered an Error
            return  "Something went wrong... Please try again later..."
        }
    } catch(e){
        return "Request error! Please try again later...";
    }

}

export function getSensorIdByName(sensors, deviceId, targetedSensorName){
    try{
        let result = null
        let filteredByDeviceid = sensors.filter(s => s.id_device === deviceId)
        let filteredBySensorTypeName = filteredByDeviceid.filter(f => f.sensor_type === targetedSensorName)
        
        if(filteredBySensorTypeName.length > 0)
            result = filteredBySensorTypeName[0].id
        
        return result
    }catch{}
}

export function countRunrateOnBudget(budgetKwh){
    let todaysDate = Number(moment().format('DD'))
    let lastDateofMonth = Number(moment().endOf('month').format('DD'))
    let result = (todaysDate / lastDateofMonth) * budgetKwh
    return result || "-"
}

export function countRunrateOnBudgetAll(budgetKwh, date){
    let currentDate = Number(moment(date).format('DD'))
    let lastDateofMonth = Number(moment(date).endOf('month').format('DD'))
    let result = (currentDate / lastDateofMonth) * budgetKwh
    return result || "-"
}

export function countRate(realKwh){
    let todaysDate = Number(moment().format('DD'))
    return realKwh / todaysDate || "-"
}

export function countPercentage(realKwh, budgetKwh){
    return (realKwh / countRunrateOnBudget(budgetKwh)) * 100 || "-"
}

export function countEstimation(realKwh){
    if(!realKwh)
        return 0
    let todaysDate = Number(moment().format('DD'))
    let lastDateofMonth = Number(moment().endOf('month').format('DD'))
    return (realKwh/todaysDate) * lastDateofMonth || "-"
}