import React from "react";

//Carbon X
const SupplyChainEmissionDashboard = React.lazy(() =>
  import("../views/CarbonX/Dashboard/SupplyChainEmission")
);

const CompanyEmissionDashboard = React.lazy(() =>
  import("../views/CarbonX/Dashboard/CompanyEmission")
);

const CompanySetting = React.lazy(() =>
  import("../views/CarbonX/CompanyDisclosure/CompanySetting/CompanySetting")
);

const Checksheets = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/ListDisclosure/Checksheets/Checksheets"
  )
);
const Asset = React.lazy(() =>
  import("../views/CarbonX/CompanyDisclosure/ListDisclosure/Asset/AssetTab")
);
const HR = React.lazy(() =>
  import("../views/CarbonX/CompanyDisclosure/ListDisclosure/HR/HumanResources")
);
const Bills = React.lazy(() =>
  import("../views/CarbonX/CompanyDisclosure/ListDisclosure/Bills/BillsTab")
);
const PurchasedGoodsAndServices = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/ListDisclosure/PurchasedGoodsAndServices/PurchasedGoodsAndServices"
  )
);
const ThirdPartyLogistics = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/ListDisclosure/ThirdPartyLogistics/ThirdPartyLogisticsTab"
  )
);

const OperationDisclosure = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/OperationDisclosure/OperationDisclosure"
  )
);
const AccountingDisclosure = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/AccountingDisclosure/AccountingDisclosure"
  )
);

const AETDashboard = React.lazy(() =>
  import("../views/CarbonX/AETDashboard/AETDashboard")
);
const AETScope1 = React.lazy(() =>
  import("../views/CarbonX/AET/Scope1/Scope1Tab")
);
const AETScope2 = React.lazy(() =>
  import("../views/CarbonX/AET/Scope2/Scope2Tab")
);
const AETScope3 = React.lazy(() =>
  import("../views/CarbonX/AET/Scope3/Scope3Tab")
);

const EmissionFactorsTab = React.lazy(() =>
  import("../views/CarbonX/EmissionFactors/EmissionFactorTab")
);

const MyData = React.lazy(() =>
  import("../views/CarbonX/EmissionDatabase/MyData/MyData")
);
const DataPool = React.lazy(() =>
  import("../views/CarbonX/EmissionDatabase/DataPool/DataPool")
);

const DecarbonizationPlan = React.lazy(() =>
  import("../views/CarbonX/DecarbonizationPlan/DecarbonizationPlan")
);

const OffsetStewardship = React.lazy(() =>
  import("../views/CarbonX/OffsetStewardship/OffsetStewardship")
);

const ReportDisclosure = React.lazy(() =>
  import("../views/CarbonX/ReportDisclosure/ReportDisclosure")
);


const ESGMetric = React.lazy(() =>
  import("../views/CarbonX/ESG/ESGMetric")
);
const ESGKPI = React.lazy(() => import("../views/CarbonX/ESG/ESGKPI"));

const DO = React.lazy(() =>
  import("../views/Fleet/DeliveryOrders/DeliveryOrders")
);

const ThreePL = React.lazy(() =>
  import("../views/Fleet/3PL/Administrative/TriPLAdministrative")
);

const Fleets = React.lazy(() =>
  import("../views/Fleet/Fleets/Fleets")
);

const FleetsOperations = React.lazy(() =>
  import("../views/Fleet/FleetOperations/FleetOperations")
);


export const routes = [
  {
    path: "/carbonx/dashboard/supplychainemission",
    name: "Supply Chain Emission",
    component: SupplyChainEmissionDashboard,
    exact: true,
  },
  {
    path: "/carbonx/dashboard/companyemission",
    name: "Company Emission",
    component: CompanyEmissionDashboard,
    exact: true,
  },
  {
    path: "/carbonx/companydisclosure/companysetting",
    name: "Company Setting",
    component: CompanySetting,
    exact: true,
  },

  {
    path: "/carbonx/listdisclosure/checksheets",
    name: "Checksheets",
    component: Checksheets,
    exact: true,
  },
  {
    path: "/carbonx/listdisclosure/asset",
    name: "Asset",
    component: Asset,
    exact: true,
  },
  {
    path: "/carbonx/listdisclosure/hr",
    name: "HR",
    component: HR,
    exact: true,
  },
  {
    path: "/carbonx/listdisclosure/bills",
    name: "Bills",
    component: Bills,
    exact: true,
  },
  {
    path: "/carbonx/listdisclosure/purchasedgoodsandservices",
    name: "Purchased Goods and Service",
    component: PurchasedGoodsAndServices,
    exact: true,
  },
  {
    path: "/carbonx/listdisclosure/thirdpartylogistics",
    name: "Third Party Logistics",
    component: ThirdPartyLogistics,
    exact: true,
  },

  {
    path: "/carbonx/companydisclosure/operationdisclosure",
    name: "Operation Disclosure",
    component: OperationDisclosure,
    exact: true,
  },
  {
    path: "/carbonx/companydisclosure/accountingdisclosure",
    name: "Accounting Disclosure",
    component: AccountingDisclosure,
    exact: true,
  },

  {
    path: "/carbonx/AET/AETDashboard",
    name: "AET Dashboard",
    component: AETDashboard,
    exact: true,
  },
  {
    path: "/carbonx/AET/scope1",
    name: "AET Scope 1",
    component: AETScope1,
    exact: true,
  },
  {
    path: "/carbonx/AET/scope2",
    name: "AET Scope 2",
    component: AETScope2,
    exact: true,
  },
  {
    path: "/carbonx/AET/scope3",
    name: "AET Scope 3",
    component: AETScope3,
    exact: true,
  },

  {
    path: "/carbonx/emissionfactors",
    name: "Emission Factors",
    component: EmissionFactorsTab,
    exact: true,
  },

  {
    path: "/carbonx/emissiondatabase/mydata",
    name: "My Data",
    component: MyData,
    exact: true,
  },
  {
    path: "/carbonx/emissiondatabase/datapool",
    name: "Data Pool",
    component: DataPool,
    exact: true,
  },

  {
    path: "/carbonx/decarbonizationplan",
    name: "Decarbonization Plan",
    component: DecarbonizationPlan,
    exact: true,
  },

  {
    path: "/carbonx/offsetstewardship",
    name: "Offset and Stewardship",
    component: OffsetStewardship,
    exact: true,
  },

  {
    path: "/carbonx/reportdisclosure",
    name: "Report Disclosure",
    component: ReportDisclosure,
    exact: true,
  },

  {
    path: "/carbonx/esgmetric/:category/:menu",
    name: "ESG Metric & Indicator",
    component: ESGMetric,
    exact: true,
  },
  {
    path: "/carbonx/esgkpi/:menu",
    name: "ESG KPI",
    component: ESGKPI,
    exact: true,
  },
  {
    path: "/trackx/do",
    name: "Delivery Orders",
    component: DO,
    exact: true,
  },
  {
    path: "/trackx/3pladministrative",
    name: "3PL Administrative",
    component: ThreePL,
    exact: true,
  },
  {
    path: "/trackx/fleets",
    name: "Fleets",
    component: Fleets,
    exact: true,
  },
  {
    path: "/trackx/fleetsoperations",
    name: "Fleets Operations",
    component: FleetsOperations,
    exact: true,
  },
];


  interface IMenu{
    name: string;
    url: string;
    icon: string | null;
    children?: IMenu[]
}

type IRemoveIcon = (item: IMenu) => IMenu

const removeIcon:IRemoveIcon = (a)=>{


    if(!!a.children){
        return {...a, children: a.children.map(removeIcon), icon: null}
    }

    return {...a, icon: null}

}

export const menu = [
  ...(process.env.IS_CARBON_ETHICS || process.env.IS_STARK_INDUSTRIES
    ? [
        {
          name: "ESG Metric & Indicator",
          url: "/carbonx/esgmetric/",
          icon: "fa fa-line-chart",
          children: [
            {
              name: "Environmental",
              url: "/carbonx/esgmetric/environmental/",
              icon: "",
              children: [
                {
                  name: "Climate Action",
                  url: "/carbonx/esgmetric/environmental/climateaction",
                  icon: "",
                },
                {
                  name: "Waste Resource Management",
                  url: "/carbonx/esgmetric/environmental/wasteresourcemanagement",
                  icon: "",
                },
              ],
            },
            {
              name: "Social",
              url: "/carbonx/esgmetric/social/",
              icon: "",
              children: [
                {
                  name: "Talent Development & Growth",
                  url: "/carbonx/esgmetric/social/talentdevelopmentgrowth",
                  icon: "",
                },
                {
                  name: "Digital Responsibility & Inclusion",
                  url: "/carbonx/esgmetric/social/digitalresponsibility",
                  icon: "",
                },
                {
                  name: "Customer Engagement",
                  url: "/carbonx/esgmetric/social/customerengagement",
                  icon: "",
                },
              ],
            },
            {
              name: "Governance",
              url: "/carbonx/esgmetric/governance/",
              icon: "",
              children: [
                {
                  name: "Risk Management",
                  url: "/carbonx/esgmetric/governance/riskmanagement",
                  icon: "",
                },
                {
                  name: "Value & Compliance",
                  url: "/carbonx/esgmetric/governance/valuecompliance",
                  icon: "",
                },
              ],
            },
          ],
        },
        {
          name: "ESG KPI",
          url: "/carbonx/esgkpi/",
          icon: "fa fa-bar-chart",
          children: [
            {
              name: "Ratings and Certifications",
              url: "/carbonx/esgkpi/ratingandcertifications",
              icon: "",
            },
            {
              name: "Risk and Financial Performance",
              url: "/carbonx/esgkpi/riskfinancialperformance",
              icon: "",
            },
            {
              name: "Targets and Progress",
              url: "/carbonx/esgkpi/targetprogress",
              icon: "",
            },
            {
              name: "Enabler Performance",
              url: "/carbonx/esgkpi/enablerperformance",
              icon: "",
            },
            {
              name: "Supplier Engagement",
              url: "/carbonx/esgkpi/supplierengagement",
              icon: "",
            },
          ],
        },
        {
          name: "TrackX",
          url: "/trackx/",
          icon: "truck icon",
          children: [
            {
              name: "Delivery Orders",
              url: "/trackx/do",
              icon: "truck icon",
            },
            {
              name: "3PL Administrative",
              url: "/trackx/3pladministrative",
              icon: "clipboard check icon",
            },
            {
              name: "Fleets",
              url: "/trackx/fleets",
              icon: "truck icon",
            },
            {
              name: "Fleets Operations",
              url: "/trackx/fleetsoperations",
              icon: "shipping fast icon",
            },
          ],
        },
        {
          name: "CarbonX",
          url: "/carbonx/",
          icon: "leaf icon",
          children: [
            {
              name: "CarbonX Dashboard",
              url: "/carbonx/dashboard/",
              icon: "tachometer alternate icon",
              children: [
                {
                  name: "Supply Chain Emission",
                  url: "/carbonx/dashboard/supplychainemission",
                  icon: "truck icon",
                },
                {
                  name: "Company Emission",
                  url: "/carbonx/dashboard/companyemission",
                  icon: "industry icon",
                },
              ],
            },
            {
              name: "Company Disclosure",
              url: "/carbonx/companydisclosure/",
              icon: "building icon",
              children: [
                {
                  name: "Company Setting",
                  url: "/carbonx/companydisclosure/companysetting",
                  icon: "cogs icon",
                },
                {
                  name: "List Disclosure",
                  url: "/carbonx/companydisclosure/listdisclosure",
                  icon: "list alternate icon",
                  children: [
                    {
                      name: "Checksheets",
                      url: "/carbonx/listdisclosure/checksheets",
                      icon: "check square icon",
                    },
                    {
                      name: "Asset",
                      url: "/carbonx/listdisclosure/asset",
                      icon: "briefcase icon",
                    },
                    {
                      name: "HR",
                      url: "/carbonx/listdisclosure/hr",
                      icon: "users icon",
                    },
                    {
                      name: "Bills",
                      url: "/carbonx/listdisclosure/bills",
                      icon: "money bill alternate icon",
                    },
                    {
                      name: "Purchased Goods and Service",
                      url: "/carbonx/listdisclosure/purchasedgoodsandservices",
                      icon: "dolly flatbed icon",
                    },
                    {
                      name: "Third Party Logistics",
                      url: "/carbonx/listdisclosure/thirdpartylogistics",
                      icon: "warehouse icon",
                    },
                    {
                      name: "Produced Goods",
                      url: "/carbonx/listdisclosure/producedgoods",
                      icon: "box icon",
                    },
                    {
                      name: "Waste",
                      url: "/carbonx/listdisclosure/waste",
                      icon: "trash alternate icon",
                    },
                  ],
                },
                {
                  name: "Operation Disclosure",
                  url: "/carbonx/companydisclosure/operationdisclosure/",
                  icon: "cog icon",
                  children: [
                    {
                      name: "Office & Commuting",
                      url: "/carbonx/operationdisclosure/officeandcommuting",
                      icon: "building icon",
                    },
                    {
                      name: "Logistics and Manufacturing",
                      url: "/carbonx/operationdisclosure/logisticsanfmanufacturing",
                      icon: "boxes icon",
                    },
                    {
                      name: "Business Travel",
                      url: "/carbonx/operationdisclosure/businesstravel",
                      icon: "truck icon",
                    },
                    {
                      name: "Land Use",
                      url: "/carbonx/operationdisclosure/landuse",
                      icon: "check square icon",
                    },
                  ],
                },
                {
                  name: "Accounting Disclosure",
                  url: "/carbonx/companydisclosure/accountingdisclosure/",
                  icon: "fa fa-calculator",
                  children: [
                    {
                      name: "Balanced Sheets",
                      url: "/carbonx/accountingdisclosure/balancedsheets",
                      icon: "balance scale icon",
                    },
                    {
                      name: "Cashflows",
                      url: "/carbonx/accountingdisclosure/cashflow",
                      icon: "money bill alternate icon",
                    },
                  ],
                },
              ],
            },
            {
              name: "Automatic Emission Tracking",
              url: "/carbonx/AET/",
              icon: "cogs icon",
              children: [
                {
                  name: "AET Dashboard",
                  url: "/carbonx/AET/AETDashboard",
                  icon: "fa fa-tachometer",
                },
                {
                  name: "Scope 1",
                  url: "/carbonx/AET/scope1",
                  icon: "industry icon",
                },
                {
                  name: "Scope 2",
                  url: "/carbonx/AET/scope2",
                  icon: "bolt icon",
                },
                {
                  name: "Scope 3",
                  url: "/carbonx/AET/scope3",
                  icon: "truck icon",
                },
                {
                  name: "Carbon Invoice",
                  url: "/carbonx/invoice",
                  icon: "fa fa-file-text-o",
                },
              ],
            },
            {
              name: "Emission Factors",
              url: "/carbonx/emissionfactors",
              icon: "cloud download icon",
            },
            {
              name: "Emission Database",
              url: "/carbonx/emissiondatabase/",
              icon: "database icon",
              children: [
                {
                  name: "My Data",
                  url: "/carbonx/emissiondatabase/mydata",
                  icon: "fa fa-bar-chart",
                },
                {
                  name: "Data Pool",
                  url: "/carbonx/emissiondatabase/datapool",
                  icon: "fa fa-table",
                },
              ],
            },
            {
              name: "Decarbonization Plan",
              url: "/carbonx/decarbonizationplan",
              icon: "leaf icon",
            },
            {
              name: "Offset and Stewardship",
              url: "/carbonx/offsetstewardship",
              icon: "fax icon",
            },
            {
              name: "Report Disclosure",
              url: "/carbonx/reportdisclosure",
              icon: "file alternate icon",
            },
          ],
        },
      ]
    : []),
];


export const menuWithoutIcon = menu.map(removeIcon)
export const menuOnlyParentsWithIcon = menu.map((item)=>{
    if(!!item.children){
        return {...item, children: item.children.map(removeIcon)} 
    }

    return {...item} 
})
