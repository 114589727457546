import * as API_LINKS from '../../../config/enermo.links';
import axios from 'axios';

//Visual Model
export function fetchVisualModelService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_LIST,
        headers: {
            Authorization: auth
        },
    });
}

//Visual Model Device
export function fetchVisualModelDeviceService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_DEVICE_LIST,
        headers: {
            Authorization: auth
        },
    });
}


//Visual Model Sensor
export function fetchVisualModelSensorService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_SENSOR_LIST,
        headers: {
            Authorization: auth
        },
    });
}

//Visual Model Utilities
export function fetchVisualModelUtilitiesService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_UTILITIES_LIST,
        headers: {
            Authorization: auth
        },
    });
}