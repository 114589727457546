import * as API_LINKS from '../../../config/enermo.links';
import axios from 'axios';


//General Setting
export function fetchGeneralSettingService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.GENERAL_SETTING_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}

//Budget Setting
export function fetchBudgetSettingService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.BUDGET_SETTING_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}
