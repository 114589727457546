import {
    FETCH_LIVE_MONITORING_DATA,
    RECEIVE_LIVE_MONITORING_DATA,
    FAILED_LIVE_MONITORING_DATA,
    FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
    RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
    FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
    FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX,
    RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX,
    FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX
} from "../../types/enermo/live-monitoring.types";

import {fetchLiveMonitoringService, 
        fetchLiveMonitoringByIdDeviceAndUnixTimeService, 
        fetchLiveMonitoringByIdDeviceIdSensorAndUnixTimeService,
        liveMonitoringFetchRestartService,
        liveMonitoringFetchCancelation} from  "./services/live-monitoring.service";

export const fetchLiveData = (idDevice, startDate=null, endDate=null) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_LIVE_MONITORING_DATA,
        });
        const response = await fetchLiveMonitoringService(auth, idDevice, startDate, endDate);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_LIVE_MONITORING_DATA,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_LIVE_MONITORING_DATA,
        });
       return Promise.reject(error); 
    }
};

export const fetchLiveDataByIdDeviceAndUnixTimeService = (idDevice, startUnixTime, endUnixTime) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
        });
        const response = await fetchLiveMonitoringByIdDeviceAndUnixTimeService(auth, idDevice, startUnixTime, endUnixTime);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
        });
       return Promise.reject(error); 
    }
};

export const fetchLiveDataByIdDeviceIdSensorAndUnixTimeService = (idDevice, idSensor, startUnixTime, endUnixTime) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX,
        });
        const response = await fetchLiveMonitoringByIdDeviceIdSensorAndUnixTimeService(auth, idDevice, idSensor, startUnixTime, endUnixTime);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX,
        });
       return Promise.reject(error); 
    }
};

export const liveMonitoringFetchRestart = () => async (dispatch) => {
    try {
        await liveMonitoringFetchRestartService();
    } catch(error) {
       
    }
};

export const liveMonitoringFetchCancelationService = () => async (dispatch) => {
    try {
        await liveMonitoringFetchCancelation();
    } catch(error) {
       
    }
};



