import React, { Component } from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import scope1 from "../../../../assets/img/scopes/scope1full.png";
import scope2 from "../../../../assets/img/scopes/scope2full.png";
import scope3 from "../../../../assets/img/scopes/scope3full.png";
import scope1_carbon from "../../../../assets/img/scopes/scope1_new.png";
import scope2_carbon from "../../../../assets/img/scopes/scope2_new.png";
import scope3_carbon from "../../../../assets/img/scopes/scope3_new.png";
import { fetchFleetListTable } from "../../../../redux/actions/transporter/aTransporter-FleetListTable";
import { fetchCarbonLoggerScope1AllFleet } from "../../../../redux/actions/carbonx/carbonx.logger-scope1.act";
import { fetchCarbonLoggerScope3Upstream } from "../../../../redux/actions/carbonx/carbonx.logger-scope3upstream.act";
import { fetchOutboundItems } from "../../../../redux/actions/carbonx/carbonx.logger-scope3downstream.act";
import { getSensorIdByName } from "../../../../helpers/enermo-helpers";
import { fetchDevices } from "../../../../redux/actions/enermo//devices.act";
import { fetchSensorList } from "../../../../redux/actions/enermo//sensor-list.act";
import {
  fetchVisualModel,
  fetchVisualModelDevice,
  fetchVisualModelUtilities,
} from "../../../../redux/actions/enermo/visual-modeling.act";
import {
  fetchLiveData,
  fetchLiveDataByIdDeviceAndUnixTimeService,
  fetchLiveDataByIdDeviceIdSensorAndUnixTimeService,
} from "../../../../redux/actions/enermo/live-monitoring.act";
import { fetchBudgetSetting } from "../../../../redux/actions/enermo/setting.act";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import Statistic from "./Statistics";
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary";
import { activeFeature } from "src/constants/constants";

class Scopes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scope1Emission: 0,
      scope1PrimaryDataPrcntg: 0,
      scope1TotalWo: 0,
      scope2Emission: 0,
      scope2TotalKwh: 0,
      scope2CarbonEm: 0,
      scope2PrimaryDataPrcntg: 100,
      scope2DataReady: false,
      scope3Emission: 0,
      scope3TotalWo: 0,
      scope3PrimaryDataPrcntg: 0,
      totalOutboundItems: 0,
    };
    this.props.fetchFleetListTable();
  }

  async componentDidMount() {
    await this.props.fetchVisualModel();
    await this.props.fetchVisualModelDevice();
    await this.props.fetchDevices();
    await this.props.fetchSensorList();
    this.setState({
      allAvailableSensors: this.props.sensorData.result,
    });
    await this.drawingData();
    const data = this.state.drawingDataState;
    if (data.length > 0) {
      await this.getYtdKwh();
      await this.getCarbonEmissionPerKwh();
      setTimeout(() => {
        this.setState(
          {
            scope2TotalKwh:
              this.state.scope2TotalKwh * this.state.scope2CarbonEm,
          },
          () => {
            this.setState({
              scope2DataReady: true,
            });
          }
        );
      }, 3000);
    }
  }

  temporaryRemoveDuplicateVMLocation(visualModelData) {
    let tempVisualModelData = [];
    let alreadyCheckLocationId = [];
    visualModelData.forEach((vm) => {
      let checkLocationIdByFilter = alreadyCheckLocationId.filter(
        (id) => id === vm.id_location
      );
      if (checkLocationIdByFilter.length === 0) {
        tempVisualModelData.push(vm);
        alreadyCheckLocationId.push(vm.id_location);
      }
    });
    return tempVisualModelData;
  }

  async getCarbonEmissionPerKwh() {
    this.props.fetchBudgetSetting().then(() => {
      const { budgetSettingData } = this.props;
      if (budgetSettingData.result) {
        let getCarbonEmission = budgetSettingData.result.filter(
          (r) => r.id_field_budget === 10003
        )[0];
        this.setState({
          scope2CarbonEm: getCarbonEmission
            ? getCarbonEmission.budget_limit
            : 0,
        });
      }
    });
  }

  async drawingData() {
    try {
      const { visualModelData, visualModelDeviceData, devicesData } =
        this.props;
      let drawingData = this.temporaryRemoveDuplicateVMLocation(
        visualModelData.result
      );
      drawingData.forEach((data) => {
        data.boundaries = visualModelDeviceData.result.filter(
          (vmd) => vmd.id_visual_model === data.id
        );
        data.boundaries.forEach((device) => {
          device.sensor_name = device.device_name;
          device.id = device.id_device;
          device.sensor_desc =
            devicesData.result.filter((d) => d.id === device.id_device) || [];
          device.sensor_desc =
            device.sensor_desc.length > 0
              ? device.sensor_desc[0].device_desc
              : null;
          device.widthAfterResize = device.width_after_resize;
          device.heightAfterResize = device.height_after_resize;
        });
      });

      this.setState({
        drawingDataState: drawingData,
      });
    } catch (e) {}
  }

  async getYtdKwh() {
    try {
      const drawingData = this.state.drawingDataState;
      const { sensorData } = this.props;
      let drawingDataToLoop = drawingData;
      let tempStart = moment().startOf("year").unix();
      let tempEnd = moment().endOf("year").unix();
      for (const drawingData of drawingDataToLoop) {
        for (const d of drawingData.boundaries) {
          if (d.sensor_desc.includes("[M]")) {
            let getNeededSensorId = getSensorIdByName(
              sensorData.result,
              d.id,
              "edel"
            );
            await this.props
              .fetchLiveDataByIdDeviceIdSensorAndUnixTimeService(
                d.id,
                getNeededSensorId,
                tempStart,
                tempEnd
              )
              .then(() => {
                return this.props.liveDataByIdDeviceIdSensorTimeUnix.result.filter(
                  (r) => r.value !== 0
                );
              })
              .then((result) => {
                this.setState({
                  scope2TotalKwh:
                    this.state.scope2TotalKwh +
                    (Number(result[result.length - 1].value) -
                      Number(result[0].value)) /
                      1000,
                });
              });
          }
        }
      }
    } catch (e) {}
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.completeFleetSelectData !== this.props.completeFleetSelectData
    ) {
      let tempModifiedFiltersInput = {
        start_date: moment().startOf("year").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
      };
      this.props.fetchCarbonLoggerScope1AllFleet(tempModifiedFiltersInput);
      this.props.fetchCarbonLoggerScope3Upstream(tempModifiedFiltersInput);
      this.props.fetchOutboundItems(
        tempModifiedFiltersInput.start_date,
        tempModifiedFiltersInput.end_date
      );
    }
    if (
      prevProps.carbonxCarbonEmissionTotal !==
      this.props.carbonxCarbonEmissionTotal
    ) {
      this.setState({
        scope1Emission: this.props.carbonxCarbonEmissionTotal,
      });
    }
    if (
      prevProps.carbonxScope1PrimaryDataPercentage !==
      this.props.carbonxScope1PrimaryDataPercentage
    ) {
      this.setState({
        scope1PrimaryDataPrcntg: this.props.carbonxScope1PrimaryDataPercentage
          ? this.props.carbonxScope1PrimaryDataPercentage
          : 0,
      });
    }
    if (prevProps.carbonxScope1TotalWO !== this.props.carbonxScope1TotalWO) {
      this.setState({
        scope1TotalWo: this.props.carbonxScope1TotalWO,
      });
    }
    if (
      prevProps.carbonxCarbonEmissionTotalScope3 !==
      this.props.carbonxCarbonEmissionTotalScope3
    ) {
      this.setState({
        scope3Emission: this.props.carbonxCarbonEmissionTotalScope3
          ? this.props.carbonxCarbonEmissionTotalScope3
          : 0,
      });
    }
    if (
      prevProps.carbonxScope3PrimaryDataPercentage !==
      this.props.carbonxScope3PrimaryDataPercentage
    ) {
      this.setState({
        scope3PrimaryDataPrcntg: this.props.carbonxScope3PrimaryDataPercentage
          ? this.props.carbonxScope3PrimaryDataPercentage
          : 0,
      });
    }
    if (prevProps.carbonxScope3TotalWo !== this.props.carbonxScope3TotalWo) {
      this.setState({
        scope3TotalWo: this.props.carbonxScope3TotalWo,
      });
    }
    if (prevProps.outboundItemTotal !== this.props.outboundItemTotal) {
      this.setState({
        totalOutboundItems: this.props.outboundItemTotal,
      });
    }
  }

  render() {
    const {
      scope1Emission,
      scope2TotalKwh,
      scope2DataReady,
      scope3Emission,
      scope1TotalWo,
      scope3TotalWo,
      totalOutboundItems,
    } = this.state;
    const {
      scope1PrimaryDataPrcntg,
      scope2PrimaryDataPrcntg,
      scope3PrimaryDataPrcntg,
    } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col md={4} xs={12}>
            <ErrorBoundary>
              <this.Scope
                type={1}
                emission={Number(scope1Emission).toFixed(2)}
                dataReady={scope1Emission ? true : false}
                prcntg={scope1PrimaryDataPrcntg}
              />
            </ErrorBoundary>
          </Col>
          <Col md={4} xs={12}>
            <ErrorBoundary>
              <this.Scope
                type={2}
                emission={Number(scope2TotalKwh).toFixed(2)}
                dataReady={scope2DataReady}
                prcntg={scope2PrimaryDataPrcntg}
              />
            </ErrorBoundary>
          </Col>
          <Col md={4} xs={12}>
            <ErrorBoundary>
              <this.Scope
                type={3}
                emission={Number(activeFeature.DEMO_COMPANY_EMISSION_SCORE_CARD_SCOPE_3 ? 25243.198 : scope3Emission).toFixed(2)}
                dataReady={activeFeature.DEMO_COMPANY_EMISSION_SCORE_CARD_SCOPE_3? true : scope3Emission ? true : false}
                prcntg={scope3PrimaryDataPrcntg}
              />
            </ErrorBoundary>
          </Col>
        </Row>
        <div style={{ "marginTop": "25px" }}></div>
        <Row>
          <Col md={12} xs={12}>
            <ErrorBoundary>
              <Statistic
                scopesEmission={{
                  scope1: scope1Emission,
                  scope2: scope2TotalKwh,
                  scope3: scope3Emission,
                }}
                scopesWo={{
                  scope1: scope1TotalWo,
                  scope3: scope3TotalWo,
                }}
                totalOutboundItems={totalOutboundItems}
              />
            </ErrorBoundary>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  Scope = (props) => {
    const HEIGHT = "auto";
    const WIDTH = "100%";
    const statusStyle = {
      background: "#ffffff",
      "fontColor": "#000000",
      "fontSize": "12pt",
      "textAlign": "center",
    };
    const { type, emission, dataReady, prcntg } = props;
    let scopeImg = null;
    switch (type) {
      case 1:
        scopeImg = process.env.IS_PLATFORM
          ? scope1
          : process.env.IS_CARBON_ETHICS
          ? scope1_carbon
          : scope1;
        break;
      case 2:
        scopeImg = process.env.IS_PLATFORM
          ? scope2
          : process.env.IS_CARBON_ETHICS
          ? scope2_carbon
          : scope2;
        break;
      case 3:
        scopeImg = process.env.IS_PLATFORM
          ? scope3
          : process.env.IS_CARBON_ETHICS
          ? scope3_carbon
          : scope3;
        break;
      default:
        break;
    }
    return (
      <>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "60%" }}>
            <img src={scopeImg} height={HEIGHT} width={WIDTH} alt="" />
          </div>
          <div style={{ background: "#ffffff", width: "40%" }}>
            <br />
            <br />
            <center>
              <p style={{ "fontSize": "14pt", "fontWeight": "bold" }}>
                Total Emission
              </p>
            </center>
            {type === 1 || type === 2 || type === 3 ? (
              <center>
                <p style={{ "fontSize": "10pt", "fontWeight": "bold" }}>
                  Year to Date
                </p>
              </center>
            ) : null}
            <center>
              {type === 1 || type === 2 || type === 3 ? (
                dataReady ? (
                  <p style={{ "fontSize": "22pt", "fontWeight": "bold" }}>
                    {this.convertToTon(emission)}
                  </p>
                ) : (
                  <>
                    <br />
                    <BarLoader color={"#123abc"} loading={!dataReady} />
                    <br />
                    <br />
                  </>
                )
              ) : (
                <p style={{ "fontSize": "22pt", "fontWeight": "bold" }}>
                  {this.convertToTon(emission)}
                </p>
              )}
              <p style={{ "fontSize": "14pt", "fontWeight": "bold" }}>
                {activeFeature.DEMO_COMPANY_EMISSION_SCORE_CARD_SCOPE_3 && type === 3 ? <>MTCO<sub>2</sub>e</> :this.getEmissionUnit(emission)}
              </p>
            </center>
          </div>
        </div>
        <div style={statusStyle}>
          Metering Status
          {type === 1 || type === 2 || type === 3 ? (
            <span style={{ color: "green" }}>
              <b> ON</b>
            </span>
          ) : (
            <span style={{ color: "red" }}>
              <b> OFF</b>
            </span>
          )}
          <span>, Primary Data {prcntg ? Number(prcntg).toFixed(2) : 0}%</span>
        </div>
      </>
    );
  };

  convertToTon = (emission) => {
    if (emission > 1000000) return this.numberStringFormatter(emission / 1000);
    return this.numberStringFormatter(emission);
  };

  getEmissionUnit = (emission) => {
    if (emission > 1000000)
      return (
        <>
          tCO<sub>2</sub>e
        </>
      );
    return (
      <>
        kgCO<sub>2</sub>e
      </>
    );
  };

  numberStringFormatter = (_digit) => {
    if (!_digit) return 0;
    return parseFloat(_digit)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
}
const mapStateToProps = (state, ownProps) => ({
  completeFleetSelectData: state.fleetListTableStore.completeFleetSelectData,
  carbonxCarbonEmissionTotal:
    state.carbonxLoggerScope1Store.carbonxCarbonEmissionTotal,
  carbonxScope1PrimaryDataPercentage:
    state.carbonxLoggerScope1Store.carbonxScope1PrimaryDataPercentage,
  carbonxScope1TotalWO: state.carbonxLoggerScope1Store.carbonxScope1TotalWO,

  totalSensor: state.sensorsStore.totalSensor,
  inProgressSensor: state.sensorsStore.inProgress,
  successSensor: state.sensorsStore.success,
  sensorData: state.sensorsStore.sensorData,

  inProgressVisualModel: state.visualModelReducerStore.inProgress,
  successVisualModel: state.visualModelReducerStore.success,
  visualModelData: state.visualModelReducerStore.visualModelData,

  inProgressVisualModelDevice: state.visualModelDeviceReducerStore.inProgress,
  successVisualModelDevice: state.visualModelDeviceReducerStore.success,
  visualModelDeviceData:
    state.visualModelDeviceReducerStore.visualModelDeviceData,

  inProgressDevice: state.devicesStore.inProgress,
  successDeviceFetch: state.devicesStore.success,
  devicesData: state.devicesStore.devicesData,

  liveDataByIdDeviceIdSensorTimeUnix:
    state.liveDataByIdDeviceIdSensorTimeUnixStore.liveData,
  inProgressLiveDataByIdDeviceIdSensorTimeUnix:
    state.liveDataByIdDeviceIdSensorTimeUnixStore.inProgress,
  successLiveDataByIdDeviceIdSensorTimeUnix:
    state.liveDataByIdDeviceIdSensorTimeUnixStore.success,

  budgetSettingData: state.budgetSettingStore.budgetSettingData,
  inProgressBudgetSetting: state.budgetSettingStore.inProgress,
  successBudgetSetting: state.budgetSettingStore.success,

  carbonxCarbonEmissionTotalScope3:
    state.carbonXLoggerScope3UpstreamStore.carbonxCarbonEmissionTotalScope3,
  carbonxScope3PrimaryDataPercentage:
    state.carbonXLoggerScope3UpstreamStore.carbonxScope3PrimaryDataPercentage,
  carbonxScope3TotalWo:
    state.carbonXLoggerScope3UpstreamStore.carbonxScope3TotalWo,
  outboundItemTotal: state.outboundItemsCarbStore.outboundItemTotal,
});
export default connect(mapStateToProps, {
  fetchFleetListTable,
  fetchCarbonLoggerScope1AllFleet,
  fetchBudgetSetting,
  fetchVisualModel,
  fetchVisualModelDevice,
  fetchVisualModelUtilities,
  fetchDevices,
  fetchSensorList,
  fetchLiveData,
  fetchLiveDataByIdDeviceAndUnixTimeService,
  fetchLiveDataByIdDeviceIdSensorAndUnixTimeService,
  fetchCarbonLoggerScope3Upstream,
  fetchOutboundItems,
})(Scopes);
