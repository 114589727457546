import axios from "axios";
import {MASTER_ITEM_DETAIL_LIST_URL} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_MASTER_ITEM_DETAIL_LIST = 'FETCH_MASTER_ITEM_DETAIL_LIST'
export const RECEIVE_MASTER_ITEM_DETAIL_LIST = 'RECEIVE_MASTER_ITEM_DETAIL_LIST'
export const FAILED_MASTER_ITEM_DETAIL_LIST = 'FAILED_MASTER_ITEM_DETAIL_LIST'

export function fetchMasterItemDetail(_master_item_detail){

    let master_item_detail = {
        id: _master_item_detail ? _master_item_detail.id : '',
        id_master_item: _master_item_detail? _master_item_detail.id_master_item : '',
        id_master_inbound: _master_item_detail? _master_item_detail.id_master_inbound : '',
        id_administration_office: _master_item_detail? _master_item_detail.id_administration_office : '',
        id_warehouse: _master_item_detail? _master_item_detail.id_warehouse: '',
        id_warehouse_area: _master_item_detail? _master_item_detail.id_warehouse_area: '',
        batch_number: _master_item_detail? _master_item_detail.batch_number: ''
    }

    return (dispatch, getState) => {
        let auth = getState().authStore.authData.result.token;
        dispatch({type: FETCH_MASTER_ITEM_DETAIL_LIST});
        axios({
            method: 'post',
            url: MASTER_ITEM_DETAIL_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: {
                id: master_item_detail.id,
                id_master_item: master_item_detail.id_master_item,
                id_master_inbound: master_item_detail.id_master_inbound,
                id_administration_office: master_item_detail.id_administration_office,
                id_warehouse: master_item_detail.id_warehouse,
                id_warehouse_area: master_item_detail.id_warehouse_area,
                batch_number: master_item_detail.batch_number,
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_MASTER_ITEM_DETAIL_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_MASTER_ITEM_DETAIL_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_MASTER_ITEM_DETAIL_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_MASTER_ITEM_DETAIL_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_MASTER_ITEM_DETAIL_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_MASTER_ITEM_DETAIL_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_MASTER_ITEM_DETAIL_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}