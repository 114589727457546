import React, { useEffect, useState } from "react";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  bootstrapTableOption,
  fileNameFormat,
} from "../../../../../helpers/helper";
import {
  Button,
  ButtonGroup,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { SPARE_PART_LIST } from "../../../../../redux/config/storing.links";
import SparepartAdd from "./SparepartAdd";
import SparepartDelete from "./SparepartDelete";
import SummaryWidgets from "../../../../Widgets/SummaryWidgets";

const SparepartDatatable = ({ id_storing_fleet_trip }) => {
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [openAddSparepart, setOpenAddSparepart] = useState(null);
  const [isGettingSparepart, setIsGettingSparepart] = useState(true);
  const [onGetSparepart, { data, loading }] = useLazyFetch({
    url: SPARE_PART_LIST,
    method: "post",
  });

  useEffect(() => {
    if (isGettingSparepart) {
      setIsGettingSparepart(false);
      onGetSparepart({
        body: {
          // id_storing_fleet_trip,
        },
      });
    }
  }, [isGettingSparepart, onGetSparepart]);

  const dataFiltered = data
    ? data.result.filter(
        (item) => item.id_storing_fleet_trip === Number(id_storing_fleet_trip)
      )
    : [];

  return (
    <>
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={dataFiltered.length}
            mainText={"Total Sparepart"}
            icon={"fa fa-id-card"}
            color={"success"}
          />
        </Col>
      </Row>
      <LoadingContent
        loading={loading}
        onReload={() => setIsGettingSparepart(true)}
      >
        <BootstrapTable
          data={dataFiltered}
          version="4"
          bordered={false}
          hover
          pagination
          search
          edit
          options={bootstrapTableOption({
            btnGroup: (props) => (
              <div>
                {props.exportCSVBtn}{" "}
                <Button
                  color={"primary"}
                  onClick={() => setIsGettingSparepart(true)}
                >
                  <i className="fa fa-refresh"></i> Reload
                </Button>{" "}
                <Button
                  color={"primary"}
                  onClick={() => setOpenAddSparepart({ id_storing_fleet_trip })}
                >
                  <i className="fa fa-plus"></i> Add Sparepart
                </Button>{" "}
              </div>
            ),
          })}
          exportCSV={true}
          csvFileName={fileNameFormat({ name: "" })}
          multiColumnSearch
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="spare_part_detail_part_number"
            dataSort
            csvHeader="Driver"
          >
            Sparepart detail part number
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="checked"
            dataSort
            csvHeader="Checked"
            dataFormat={(cell) => <>{cell === 1 ? "Checked" : ""}</>}
          >
            Checked
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            dataFormat={(cell, row) => (
              <>
                <ButtonGroup>
                  <Button
                    size="sm"
                    color="warning"
                    onClick={() =>
                      setModalOpenEdit({ ...row, id_storing_fleet_trip })
                    }
                  >
                    <i className="fa fa-edit"></i> Edit
                  </Button>
                  <Button
                    className="btn btn-danger"
                    color="danger"
                    onClick={() => setModalOpenDelete({ ...row })}
                  >
                    <i className="fa fa-trash"></i> Delete
                  </Button>
                </ButtonGroup>
              </>
            )}
          >
            Action
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingContent>

      <Modal
        size="md"
        isOpen={!!openAddSparepart}
        toggle={() => setOpenAddSparepart(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenAddSparepart(null)}>
          Add Sparepart
        </ModalHeader>
        <ModalBody>
          <SparepartAdd
            initialValues={openAddSparepart}
            onClose={() => setOpenAddSparepart(null)}
            onReload={() => setIsGettingSparepart(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Sparepart
        </ModalHeader>
        <ModalBody>
          <SparepartAdd
            isEdit
            initialValues={modalOpenEdit}
            onClose={() => setModalOpenEdit(null)}
            onReload={() => setIsGettingSparepart(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Mechanic
        </ModalHeader>
        <ModalBody>
          <SparepartDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setIsGettingSparepart(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SparepartDatatable;
