import { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }
    render() {
        return (
            <div className="app flex-row align-items-center"> 
                {/* <ToastContainer position="bottom-right" autoClose={7500} style={toastStyle}/> */}
                    <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                        <CardGroup>
                            <Card className="p-4">
                            <CardBody>
                                <Form onSubmit={this.handleSubmit}>
                                <h1>Login</h1>
                                <p className="text-muted">Sign In to Automa Platform</p>
                                <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="icon-user"></i>
                                    </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="email" placeholder="Email" autoComplete="email" id="emailUser"/>
                                </InputGroup>
                                <InputGroup className="mb-4">
                                    <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="icon-lock"></i>
                                    </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="password" placeholder="Password" autoComplete="current-password" id="passUser"/>
                                </InputGroup>
                                <Row>
                                    <Col xs="6">
                                    <Button color="primary" type="submit" className="px-4" disabled={this.props.inProgress}>Login</Button>
                                    </Col>
                                    {/* <Col xs="6" className="text-right">
                                    <Button color="link" className="px-0">Forgot password?</Button>
                                    </Col> */}
                                    {/* {this.props.isAuthenticated && (
                                        <Redirect to={from || '/dashboard'}/>)} */}
                                </Row>
                                </Form>
                            </CardBody>
                            </Card>
                        </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Register;