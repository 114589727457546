import axios from "axios";
import {MASTER_OUTBOUND_LIST_URL} from '../../config/stck.links'
import {toast} from 'react-toastify'

export const FETCH_MASTER_OUTBOUND_LIST = 'FETCH_MASTER_OUTBOUND_LIST'
export const RECEIVE_MASTER_OUTBOUND_LIST = 'RECEIVE_MASTER_OUTBOUND_LIST'
export const FAILED_MASTER_OUTBOUND_LIST = 'FAILED_MASTER_OUTBOUND_LIST'

export function fetchMasterOutbound(withFilterWO=false){
    let data = null
    if(withFilterWO){
        data = {
            filterWO: 1
        }
    }
    return (dispatch, getState) => {
        let auth = getState().authStore.authData.result.token;
        dispatch({type: FETCH_MASTER_OUTBOUND_LIST});
        axios({
            method: 'post',
            url: MASTER_OUTBOUND_LIST_URL,
            headers: {
                Authorization: auth
            },
            data
        })
        .then((response) => {
            response.data.result.forEach(r => {
                r.is_intercompany = 1
                if(!r.company_receiver){
                    r.company_receiver = r.customer_name
                    r.is_intercompany = 0
                }
                if(r.outbound_human_id){
                    r.outbound_number = r.outbound_human_id
                }
                if(r.so_detail.length > 0 && !r.invoice_type){
                    r.invoice_type = r.so_detail[0].invoice_type
                }
            })
            dispatch( {
                type: RECEIVE_MASTER_OUTBOUND_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_MASTER_OUTBOUND_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_MASTER_OUTBOUND_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_MASTER_OUTBOUND_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_MASTER_OUTBOUND_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_MASTER_OUTBOUND_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_MASTER_OUTBOUND_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export const FETCH_MASTER_OUTBOUND_IN_DO_LIST = 'FETCH_MASTER_OUTBOUND_IN_DO_LIST'
export const RECEIVE_MASTER_OUTBOUND_IN_DO_LIST = 'RECEIVE_MASTER_OUTBOUND_IN_DO_LIST'
export const FAILED_MASTER_OUTBOUND_IN_DO_LIST = 'FAILED_MASTER_OUTBOUND_IN_DO_LIST'

export function fetchMasterOutboundByDoWoData(workOrdersTableData){
    return (dispatch, getState) => {
        let auth = getState().authStore.authData.result.token;
        dispatch({type: FETCH_MASTER_OUTBOUND_IN_DO_LIST});
        let fetchOutbounds= []
        let outItemResult = {}
        workOrdersTableData.forEach(wo => {
            let idStockerOutbound = wo.id_stocker_outbound_saved? wo.id_stocker_outbound_saved : 
            wo.work_order_from? wo.work_order_from.id_outbound_stocker : null
            if(!idStockerOutbound)
                return
            outItemResult[idStockerOutbound] = []
            fetchOutbounds.push(
                axios({
                    method: 'post',
                    url: MASTER_OUTBOUND_LIST_URL,
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        id: idStockerOutbound
                    }
                })
            )
        })
        Promise.all([...fetchOutbounds])
        .then((responses) => {
            responses.forEach((r)=> {
                let idMasterOutbound = r.data.result[0]? r.data.result[0].id : null
                if(!idMasterOutbound)
                    return
                outItemResult[idMasterOutbound] = [...r.data.result]
            })
            dispatch( {
                type: RECEIVE_MASTER_OUTBOUND_IN_DO_LIST,
                payload: outItemResult
            })
        })
    }
}