import axios from "axios";
import { MASTER_INBOUND_LIST_URL } from "../../config/stck.links";
import { toast } from "react-toastify";

export const FETCH_MASTER_INBOUND_LIST = "FETCH_MASTER_INBOUND_LIST";
export const RECEIVE_MASTER_INBOUND_LIST = "RECEIVE_MASTER_INBOUND_LIST";
export const FAILED_MASTER_INBOUND_LIST = "FAILED_MASTER_INBOUND_LIST";

export function fetchMasterInbound() {
  return (dispatch, getState) => {
    let auth = getState().authStore.authData.result.token;
    dispatch({ type: FETCH_MASTER_INBOUND_LIST });
    axios({
      method: "post",
      url: MASTER_INBOUND_LIST_URL,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVE_MASTER_INBOUND_LIST,
          payload: response.data,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_MASTER_INBOUND_LIST,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_MASTER_INBOUND_LIST,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_MASTER_INBOUND_LIST,
            });
            return toast.error(error.response.data.message);
          } else {
            dispatch({
              type: FAILED_MASTER_INBOUND_LIST,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_MASTER_INBOUND_LIST,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          dispatch({
            type: FAILED_MASTER_INBOUND_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}
