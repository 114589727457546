import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_FLEET_LIST_TABLE = 'FETCH_FLEET_LIST_TABLE'
export const RECEIVE_FLEET_LIST_TABLE = 'RECEIVE_FLEET_LIST_TABLE'
export const FAILED_FLEET_LIST_TABLE = 'FAILED_FLEET_LIST_TABLE'

export const FETCH_FLEET_QR = 'FETCH_FLEET_QR'
export const RECEIVE_FLEET_QR = 'RECEIVE_FLEET_QR'
export const FAILED_FLEET_QR = 'FAILED_FLEET_QR'

export const FETCH_FLEET_MAINTENANCE_BIND = 'FETCH_FLEET_MAINTENANCE_BIND'
export const RECEIVE_FLEET_MAINTENANCE_BIND = 'RECEIVE_FLEET_MAINTENANCE_BIND'
export const FAILED_FLEET_MAINTENANCE_BIND = 'FAILED_FLEET_MAINTENANCE_BIND'

export function fetchFleetListTable(){

    return (dispatch, getState) => {

        let auth = getState().authStore.authData.result.token;
        dispatch({type: FETCH_FLEET_LIST_TABLE});
        axios({
            method: 'post',
            url: API_LINKS.FLEET_LIST_URL,
            headers: {
                Authorization: auth
            },
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_FLEET_LIST_TABLE,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_LIST_TABLE
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_LIST_TABLE
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_LIST_TABLE
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_LIST_TABLE
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_LIST_TABLE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_LIST_TABLE
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchFleetsQR(fleets){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_QR});
        new Promise(function(resolve, reject) {
            if(!Array.isArray(fleets))
                throw new Error("");
            if(fleets.length === 0)
                throw new Error("");
            let fetchFleetsQr = []
            for(let data of fleets){
                fetchFleetsQr.push(
                    axios({
                        method: 'post',
                        url: API_LINKS.GENERAL_QR_GENERATE,
                        headers: {
                            Authorization: auth
                        },
                        data:{
                            id: data.id,
                            type: 1
                        }
                    })
                )
            }
            resolve(fetchFleetsQr);
        })
        .then((fetchFleetsQr) => {
            Promise.all([...fetchFleetsQr])
            .then(responses => {
                let counter = 0
                for(let response of responses){
                    let responseResult = response.data.result
                    if(!responseResult)
                        continue
                    if(!responseResult.length > 0)
                        continue
                    fleets[counter].qr = responseResult
                    counter +=1 
                }
                return fleets
            })
            .then(fleets => {
                dispatch( {
                    type: RECEIVE_FLEET_QR,
                    payload: fleets
                })
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_QR
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_QR
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_QR
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_QR
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_QR
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_QR
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

export function fetchFleetMaintenanceBindList(_id_fleet){
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_FLEET_MAINTENANCE_BIND});
        axios({
            method: 'post',
            url: API_LINKS.FLEET_MAINTENANCE_BINDING_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id_fleet: _id_fleet
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_FLEET_MAINTENANCE_BIND,
                payload: response.data.result.filter(r => r.id_status !== 2)
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_BIND
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_BIND
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_BIND
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_BIND
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_MAINTENANCE_BIND
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_MAINTENANCE_BIND
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}