import axios from "axios";
import {MASTER_INBOUND_LIST_URL, INBOUND_ITEM_DETAIL_LIST_URL, MASTER_OUTBOUND_LIST_URL, OUTBOUND_ITEM_DETAIL_LIST_URL} from '../../config/stck.links'
import {toast} from 'react-toastify'
import moment from 'moment'

export const FETCH_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST = 'FETCH_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST'
export const RECEIVE_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST = 'RECEIVE_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST'
export const FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST = 'FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST'

export const FECTH_OUTBOUND_ITEM_LIST = 'FECTH_OUTBOUND_ITEM_LIST'
export const RECEIVE_OUTBOUND_ITEM_LIST = 'RECEIVE_OUTBOUND_ITEM_LIST'
export const FAILED_OUTBOUND_ITEM_LIST = 'FAILED_OUTBOUND_ITEM_LIST'

export function fetchInboundItemBatches(start_inbound_date, end_inbound_date){
    return (dispatch, getState) => {
        let auth = getState().authStore.authData.result.token;
        try{
            dispatch({type: FETCH_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST});
            axios({
                method: 'post',
                url: MASTER_INBOUND_LIST_URL,
                headers: {
                    Authorization: auth
                },
            }).then(async response => {
                if(response.status === 200){
                    let fetchsOfInboundItem = []
                    let filteredResponseByDate = await response.data.result.filter(d => 
                        moment(d.in_time).format("YYYY-MM-DD") >= moment(start_inbound_date).format("YYYY-MM-DD") 
                        &&
                        moment(d.in_time).format("YYYY-MM-DD") <= moment(end_inbound_date).format("YYYY-MM-DD")
                    )
                    if(filteredResponseByDate.length === 0){
                        dispatch( {
                            type: RECEIVE_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST,
                            payload: []
                        })
                    }
                    for(let data of filteredResponseByDate){
                        fetchsOfInboundItem.push(
                            axios({
                                method: 'post',
                                url: INBOUND_ITEM_DETAIL_LIST_URL,
                                headers: {
                                    Authorization: auth
                                },
                                data:{
                                    id_master_inbound: data.id
                                }
                            })
                        )
                    }
                    Promise.all([...fetchsOfInboundItem])
                    .then(responses => {
                        let payload = []
                        for(let response of responses){
                            let responseResult = response.data.result
                            if(!responseResult)
                                continue
                            if(!responseResult.length > 0)
                                continue
                            responseResult.map(r => {
                                let inboundDate = filteredResponseByDate.filter(d => d.id === r.id_master_inbound)[0]
                                r["inbound_date"] = inboundDate? moment(inboundDate.in_time).format("YYYY-MM-DD") : null    
                                return r;
                            })
                            payload = [...payload, ...responseResult]
                        }
                        return payload
                    })
                    .then(payload => {
                        dispatch( {
                            type: RECEIVE_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST,
                            payload: payload
                        })
                    })
                }
            })  
        }catch(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_CARBON_LOGGER_SCOPE3_DOWNSTREAM_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        }
    }
}

export function fetchOutboundItems(start_outbound_date, end_outbound_date){
    return (dispatch, getState) => {
        let auth = getState().authStore.authData.result.token;
        try{
            dispatch({type: FECTH_OUTBOUND_ITEM_LIST});
            axios({
                method: 'post',
                url: MASTER_OUTBOUND_LIST_URL,
                headers: {
                    Authorization: auth
                },
            }).then(async response => {
                if(response.status === 200){
                    let fetchsOfOutboundItem = []
                    let filteredResponseByDate = await response.data.result.filter(d => 
                        moment(d.outbound_issue_date).format("YYYY-MM-DD") >= moment(start_outbound_date).format("YYYY-MM-DD") 
                        &&
                        moment(d.outbound_issue_date).format("YYYY-MM-DD") <= moment(end_outbound_date).format("YYYY-MM-DD")
                    )
                    if(filteredResponseByDate.length === 0){
                        dispatch( {
                            type: RECEIVE_OUTBOUND_ITEM_LIST,
                            payload: []
                        })
                    }
                    for(let data of filteredResponseByDate){
                        fetchsOfOutboundItem.push(
                            axios({
                                method: 'post',
                                url: OUTBOUND_ITEM_DETAIL_LIST_URL,
                                headers: {
                                    Authorization: auth
                                },
                                data:{
                                    id_master_outbound: data.id
                                }
                            })
                        )
                    }
                    Promise.all([...fetchsOfOutboundItem])
                    .then(responses => {
                        let payload = []
                        for(let response of responses){
                            let responseResult = response.data.result
                            if(!responseResult)
                                continue
                            if(!responseResult.length > 0)
                                continue
                            responseResult.map(r => {
                                let outbound = filteredResponseByDate.filter(d => d.id === r.id_master_outbound)[0]
                                r["outbound_date"] = outbound? moment(outbound.in_time).format("YYYY-MM-DD") : null    
                                return r;
                            })
                            payload = [...payload, ...responseResult]
                        }
                        return payload
                    })
                    .then(payload => {
                        dispatch( {
                            type: RECEIVE_OUTBOUND_ITEM_LIST,
                            payload: payload
                        })
                    })
                }
            })  
        }catch(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_OUTBOUND_ITEM_LIST
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_OUTBOUND_ITEM_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_OUTBOUND_ITEM_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_OUTBOUND_ITEM_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_OUTBOUND_ITEM_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_OUTBOUND_ITEM_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        }
    }
}