import {
    FETCH_DEVICE_LIST,
    RECEIVE_DEVICE_LIST,
    FAILED_DEVICE_LIST,
} from "../../types/enermo/devices.types";

import {fetchDevicesService} from  "./services/devices.service";

export const fetchDevices = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_DEVICE_LIST,
        });
        const response = await fetchDevicesService(auth);
        if(response.status === 200){

                dispatch({
                    type: RECEIVE_DEVICE_LIST,
                    payload: response.data
                });

        }
    } catch(error) {
        dispatch({
            type: FAILED_DEVICE_LIST,
        });
       return Promise.reject(error);
    }
};