import * as API_LINKS from '../../../config/enermo.links';
import axios from 'axios';

let controllerLiveMonitoring =null

export function fetchLiveMonitoringService(auth, idDevice){
    return  axios({
                method: 'post',
                url: API_LINKS.LIVE_MONITORING_FETCH_URL,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id : String(idDevice),
                }
            });
}

export function fetchLiveMonitoringByIdDeviceAndUnixTimeService(auth, idDevice, startUnixTime, endUnixTime){
    // console.log(auth)
    return  axios({
                method: 'post',
                url: API_LINKS.LIVE_MONITORING_FETCH_URL,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id : String(idDevice),
                    start_date: String(startUnixTime),
                    end_date: String(endUnixTime),
                }
            });
}

export function fetchLiveMonitoringByIdDeviceIdSensorAndUnixTimeService(auth, idDevice, idSensor, startUnixTime, endUnixTime){
    return  axios({
                method: 'post',
                url: API_LINKS.LIVE_MONITORING_FETCH_URL,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id : String(idDevice),
                    id_sensor: String(idSensor),
                    start_date: String(startUnixTime),
                    end_date: String(endUnixTime)
                }
            });
}

export function liveMonitoringFetchRestartService(){
    controllerLiveMonitoring = new AbortController();
}

export function liveMonitoringFetchCancelation(){
    controllerLiveMonitoring.abort()
}